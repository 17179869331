const initialState = {
  visible: !localStorage.getItem('hideBanner'),
};

export const cookieReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VISIBLE':
      return {
        ...state,
        visible: action.payload.visible,
      };
    default:
      return state;
  }
};
