const initialState = {
  token: localStorage.getItem('token'),
  account: localStorage.getItem('account'),
};

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT':
      return {
        ...state,
        account: action.payload.account,
      };
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.payload.token,
      };
    default:
      return state;
  }
};
