import React from 'react';
import { Field } from 'react-final-form';
import FormValidation from './FormValidation';
import { isValidationErr } from '../../../utils/form';

const FormTextField = ({
  formName,
  spanText,
  placeholder,
  validate,
  onChange,
  type,
  value,
  disabled,
  hyperlinkButton,
  autoComplete,
}) => {
  const inputType = type || 'text';
  return (
    <Field name={formName} validate={validate}>
      {({ input, meta }) => {
        return (
          <div className="custom-form-group">
            {spanText && <span className="input-text">{spanText}</span>}
            <input
              type={inputType}
              className="text"
              autoComplete={autoComplete}
              {...input}
              placeholder={placeholder}
              value={value === null || value === undefined ? input.value : value}
              onChange={(e) => {
                input.onChange(e);

                if (onChange) {
                  onChange(e);
                }
              }}
              disabled={disabled}
            />
            {hyperlinkButton && (
              <button
                type="button"
                className="hyperlink-button"
                onClick={hyperlinkButton.onClick}
                style={{
                  marginRight: isValidationErr(meta) ? '36px' : '12px',
                  color: isValidationErr(meta) ? '#FF4848' : '#D9D9D9',
                }}
              >
                {hyperlinkButton.text}
              </button>
            )}
            <FormValidation meta={meta} />
          </div>
        );
      }}
    </Field>
  );
};

export default FormTextField;
