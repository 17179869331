import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonRemove } from '../../icons/ButtonRemove';
import { store } from '../../store';
import { setFiles } from '../../store/transfer/actions';
import { formatBytes } from '../../utils';
import DelayedHover from '../common/DelayedHover';
import FilePreview from './FilePreview';

const File = ({ file, index }) => {
  let { files } = useSelector((state) => state.transfer);

  const removeFile = (index) => {
    files.splice(index, 1);
    store.dispatch(setFiles(files));
  };

  return (
    <DelayedHover
      component={
        <div className="file-preview-hover">
          <span className="file-name-hover">{file.name}</span>
          <span className="file-size-hover">{formatBytes(file.size)}</span>
        </div>
      }
      time={500}
      className="file-preview"
    >
      <FilePreview file={file} />
      <div className="file-remove" onClick={() => removeFile(index)}>
        <ButtonRemove />
      </div>
      <div className="file-preview-overlay">
        <span className="file-name">{file.name}</span>
        <span className="file-size">{formatBytes(file.size)}</span>
      </div>
    </DelayedHover>
  );
};

export default File;
