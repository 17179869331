import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import contactus from '../../assets/images/contact-us.png';
import { SocialEmail } from '../../icons/SocialEmail';
import { SocialFacebook } from '../../icons/SocialFacebook';
import { SocialInstagram } from '../../icons/SocialInstagram';
import { SocialLinkedin } from '../../icons/SocialLinkedin';
import { SocialTwitter } from '../../icons/SocialTwitter';
import { composeValidators, required } from '../../utils/form';
import FormTextArea from '../common/form/FormTextArea';
import FormTextField from '../common/form/FormTextField';
import '../../scss/components/common/form.scss';
import '../../scss/pages/contact-us.scss';
import api from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import useExitPrompt from '../common/useExitPrompt';

const ContactUsPage = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({ email: '', subject: '', body: '' });
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  useEffect(() => {
    const prevColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#fff';
    document.body.style.overflow = 'auto';
    return () => {
      document.body.style.backgroundColor = prevColor;
      document.body.style.overflow = 'hidden';
    };
  }, []);

  useEffect(() => {
    console.log({ showExitPrompt });
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  const onSubmit = async (form) => {
    const waitTime = 5 * 1000;

    try {
      await api.post(`/contact`, {
        email: form.email,
        subject: form.subject,
        body: form.body,
      });

      toast.success("Great! Thank you for reaching out 🦅. \nYou'll be redirected shortly.", {
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'toast-message',
        autoClose: waitTime,
        pauseOnHover: true,
        onClose: () => {
          history.push('/');
          return;
        },
      });

      setFormData({ email: '', subject: '', body: '' });
      setShowExitPrompt(false);
    } catch (error) {
      toast.error('Something went wrong. Try again later.', {
        position: toast.POSITION.BOTTOM_CENTER,
      });

      throw error;
    }
  };

  return (
    <div className="contact-us">
      <ToastContainer />
      <Container>
        <Row>
          <Col md={6} className="image-container">
            <img src={contactus} />
          </Col>
          <Col md={6} className="form-container">
            <div>
              <Row className="text-container">
                <h2>We are here.</h2>
                <p>Our team is always open for a good talk or question.</p>
              </Row>
              <Row>
                <Form
                  onSubmit={onSubmit}
                  render={({ handleSubmit, submitting }) => (
                    <form>
                      <Row>
                        <Col md={6}>
                          <FormTextField
                            formName="email"
                            spanText="Your Email"
                            value={formData.email}
                            onChange={(e) => {
                              setFormData({ ...formData, email: e.target.value });
                              setShowExitPrompt(true);
                            }}
                            validate={composeValidators(required)}
                          />
                        </Col>
                        <Col md={6}>
                          <FormTextField
                            formName="subject"
                            spanText="Subject"
                            value={formData.subject}
                            onChange={(e) => {
                              setFormData({ ...formData, subject: e.target.value });
                              setShowExitPrompt(true);
                            }}
                            validate={composeValidators(required)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormTextArea
                            formName="body"
                            spanText="Message"
                            value={formData.body}
                            onChange={(e) => {
                              setFormData({ ...formData, body: e.target.value });
                              setShowExitPrompt(true);
                            }}
                            placeholder="Type your message here"
                            maxLength={1000}
                          />
                        </Col>
                      </Row>
                      <Row className="send-container">
                        <Col md={9} className="social-container">
                          <Link to={{ pathname: 'mailto:info@safetp.com' }} target="_blank">
                            <SocialEmail />
                          </Link>
                          <Link to={{ pathname: '' }} target="_blank">
                            <SocialFacebook />
                          </Link>
                          <Link to={{ pathname: 'https://www.instagram.com/safetpcom' }} target="_blank">
                            <SocialInstagram />
                          </Link>
                          <Link to={{ pathname: 'https://www.linkedin.com/company/safetp' }} target="_blank">
                            <SocialLinkedin />
                          </Link>
                          <Link to={{ pathname: 'https://twitter.com/SafeTPcom' }} target="_blank">
                            <SocialTwitter />
                          </Link>
                        </Col>
                        <Col md={3}>
                          <Button type="button" onClick={handleSubmit} className="btn-send" disabled={submitting}>
                            Send
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  )}
                />
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUsPage;
