import React from 'react';

export const HorizontalBar = () => {
  return (
    <svg width="95" height="12" viewBox="0 0 95 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="95" height="12" rx="6" fill="url(#paint0_linear_176_4483)" fillOpacity="0.5" />
      <defs>
        <linearGradient
          id="paint0_linear_176_4483"
          x1="47.5"
          y1="-1.17968e-07"
          x2="48"
          y2="16.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
