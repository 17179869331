import React from 'react';

export const FileIcon = ({ width, height }) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width || '48'} height={height || '48'} fill="#FFFFFF" />
      <path
        d="M12 4C9.81149 4 8 5.81149 8 8V40C8 42.1885 9.81149 44 12 44H36C38.1885 44 40 42.1885 40 40V16L28 4H12ZM12 8H26V18H36V40H12V8Z"
        fill="#8892A4"
      />
    </svg>
  );
};
