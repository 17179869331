import React from 'react';

export const SocialEmail = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 6C4.3425 6 3 7.3425 3 9V27C3 28.6575 4.3425 30 6 30H30C31.6575 30 33 28.6575 33 27V9C33 7.3425 31.6575 6 30 6H6ZM6 9H30V10.5029L18 18L6 10.5029V9ZM6 13.5029L18 21L30 13.5029V27H6V13.5029Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
