import React from 'react';

export const DisablePasswordIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.5" stroke="#D9D9D9" />
      <path
        d="M5.60262 4.75L4.75 5.60262L9.14738 10L4.75 14.3974L5.60262 15.25L10 10.8526L14.3974 15.25L15.25 14.3974L10.8526 10L15.25 5.60262L14.3974 4.75L10 9.14738L5.60262 4.75Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
