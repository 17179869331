export const themes = {
  black: {
    primary: '#192E41',
    secondary: '#0284FD',
    text: '#FFFFFF',
  },
  blue: {
    primary: '#478FF3',
    secondary: '#FFAB48',
    text: '#FFFFFF',
  },
  gray: {
    primary: '#8892A4',
    secondary: '#0284FD',
    text: '#FFFFFF',
  },
  orange: {
    primary: '#FFAB48',
    secondary: '#478FF3',
    text: '#FFFFFF',
  },
  pink: {
    primary: '#A938D0',
    secondary: '#8892A4',
    text: '#FFFFFF',
  },
};
