import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { setStatus, setTransferGUID, setSubmittedFiles, setTotalSize } from '../../../store/transfer/actions';
import { useSelector, useDispatch } from 'react-redux';
import { store } from '../../../store';
import FilePreview from '../FilePreview';
import { setExpirationDate, formatBytes } from '../../../utils';
import { AddPasswordIcon } from '../../../icons/AddPasswordIcon';
import OkayIcon from '../../../icons/OkayIcon';
import { ValidationErrorIcon } from '../../../icons/ValidationErrorIcon';
import ExpirationIcon from '../../../icons/ExpirationIcon';
import { SENDING_STATUS } from '../../../utils/constants';
import api from '../../../api';
import { gtagEvent } from '../../../utils/analytics';

const Preview = ({ errorCallback }) => {
  const dispatch = useDispatch();
  let { transactionId } = useSelector((state) => state.app);
  let { files, form } = useSelector((state) => state.transfer);

  const createTransferRequest = () => async () => {
    try {
      const { data } = await api.post('/transfers/', {
        transactionId,
        senderEmail: form.email,
        files: files.map((file) => {
          return {
            name: file.name,
            size: file.size,
            clientGUID: file.clientGUID,
          };
        }),
      });

      return data;
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        throw new Error(error.response.data.error.code);
      }

      throw error;
    }
  };

  const sendFiles = async (data) => {
    store.dispatch(setTransferGUID(data.transferGUID));
    store.dispatch(setSubmittedFiles(data.files));

    let totalSize = 0;
    files.forEach((file) => {
      totalSize += file.size;
    });

    store.dispatch(setTotalSize(totalSize));
    gtagEvent({
      action: 'upload_start',
      category: 'Transfer',
      label: 'Upload Start',
      value: 1,
    });
    store.dispatch(setStatus(SENDING_STATUS.SUBMIT));
  };

  const createTransfer = async () => {
    try {
      const { data } = await dispatch(createTransferRequest());
      if (!data.verifyRequired) {
        await sendFiles(data);
      } else {
        gtagEvent({
          action: 'verify_transfer_email',
          category: 'Transfer',
          label: 'Verify Transfer Email',
          value: 1,
        });
        store.dispatch(setTransferGUID(data.transferGUID));
        store.dispatch(setStatus(SENDING_STATUS.VERIFY_REQUIRED));
      }
    } catch (error) {
      if (error.message === 'verify_transfer_email_limit_exceeded') {
        errorCallback('You reached maximum email limit please try again later');
        return;
      }

      errorCallback('Something went wrong');
    }
  };

  return (
    <div>
      <p className="title">Does everything seem good?</p>
      <Row className="transfer-info">
        <Col>
          <p className="col-title">Files:</p>
          <div className="col-info">
            <div className="list">
              {files.map((file, index) => {
                return (
                  <div className="file-info" key={index}>
                    <FilePreview file={file} width="20" height="20" imagePreview={false} />
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">{formatBytes(file.size)}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </Col>

        {form.toEmailList.length > 0 && (
          <Col>
            <p className="col-title">People:</p>
            <div className="col-info">
              <div className="list">
                {form.toEmailList.map((email, index) => {
                  return (
                    <div className="people-info" key={index}>
                      <span className="email-to" style={{ color: email.color, border: `2.5px solid ${email.color}` }}>
                        {email.email[0].toUpperCase()}
                      </span>
                      <span className="email">{email.email}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Row>
        <Col>
          <div className="password-info">
            <AddPasswordIcon color="#2C2C2C" />
            <span>Password: </span>

            {form.password ? (
              <div>
                <span className="yes">Yes</span>
                <OkayIcon color="#38D047" />
              </div>
            ) : (
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <span className="no">No</span>
                <div className="no-icon">
                  <ValidationErrorIcon />
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col>
          <div className="expiration-info">
            <ExpirationIcon />
            <span>Expiration: {setExpirationDate(form.expire.duration)}</span>
          </div>
        </Col>
      </Row>
      <Row className="buttons">
        <Col md={4} xs={12}></Col>
        <Col md={4} xs={12}>
          <Button
            className="cancel-button"
            onClick={() => {
              store.dispatch(setStatus(SENDING_STATUS.CREATED));
            }}
          >
            No, Edit the Details
          </Button>
        </Col>
        <Col md={4} xs={12}>
          <Button className="confirm-button" onClick={createTransfer}>
            Yes, Send the Files
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Preview;
