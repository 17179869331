import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RecentTransferIcon } from '../../icons/RecentTransferIcon';
import { MyGroupIcon } from '../../icons/MyGroupIcon';
import { TermsIcon } from '../../icons/TermsIcon';
import { ContactUsIcon } from '../../icons/ContactUsIcon';
import '../../scss/components/common/menu.scss';
import { haveAccount } from '../../utils';

const Menu = (props) => {
  const { show } = props;

  return (
    <Container
      fluid
      className="menu"
      style={{
        display: show ? 'flex' : 'none',
      }}
    >
      <Row>
        <MenuItem text={'SafeZone'} link={'safezone'} Icon={ContactUsIcon} />
      </Row>
      {haveAccount() && (
        <Row>
          <MenuItem text={'Recent Transfers'} link={'recent-transfers'} Icon={RecentTransferIcon} />
        </Row>
      )}
      <Row>
        <MenuItem text={'Contact Us'} link={'contact-us'} Icon={MyGroupIcon} />
      </Row>
      <Row>
        <MenuItem text={'Terms & Conditions'} link={'terms-conditions'} Icon={TermsIcon} />
      </Row>
    </Container>
  );
};

const MenuItem = (props) => {
  const { text, link, Icon } = props;

  return (
    <div className="menu-item">
      <Link to={`/${link}`}>
        <Icon />
        <p>
          <strong>{text}</strong>
        </p>
      </Link>
    </div>
  );
};

export default Menu;
