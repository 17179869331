import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { Form } from 'react-final-form';

import { PasswordIcon } from '../../icons/PasswordIcon';
import FormTextField from '../common/form/FormTextField';
import api from '../../api';
import { required } from '../../utils/form';

const PasswordColumn = ({ id, transfer, callback }) => {
  const [password, setPassword] = React.useState('');
  const fetchTransfer = async () => {
    try {
      const params = {
        password,
        page: 'download',
      };

      const response = await api.get(`/transfers/${id}/protected`, { params });
      const { data } = response.data;

      callback(data);
    } catch (error) {
      if (error?.response?.data?.error?.code === 'wrong_credentials') {
        return { password: 'Wrong Password' };
      }

      return { password: 'Something went wrong' };
    }
  };

  return (
    <Col className="password-col" md={4}>
      <div className="sender">
        Files sent by: <span>{transfer.senderEmail}</span>
      </div>
      <div className="password-icon">
        <PasswordIcon width="96" height="96" />
      </div>
      <h1>The files are password protected.</h1>
      <Form
        onSubmit={fetchTransfer}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitting }) => (
          <form className="password-field">
            <FormTextField
              placeholder="Password"
              formName="password"
              type="password"
              validate={required}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Button className="button" onClick={handleSubmit} disabled={submitting}>
              Access Files
            </Button>
          </form>
        )}
      />
    </Col>
  );
};

export default PasswordColumn;
