import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import '../../scss/components/common/account-header.scss';
import LogoutButton from './LogoutButton';

const AccountHeader = () => {
  const { account } = useSelector((state) => state.account);

  return (
    <div className="account-header">
      <p>
        {account.firstName} {account.lastName}
      </p>
      <div className="actions">
        <Link className="link" to="/profile">
          My Account
        </Link>
        <span>&nbsp;-&nbsp;</span>
        <LogoutButton />
      </div>
    </div>
  );
};

export default AccountHeader;
