import React from 'react';

export const RecentTransferIcon = ({ color }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0C0.905745 0 0 0.905745 0 2V14C0 15.0943 0.905745 16 2 16H18C19.0943 16 20 15.0943 20 14V4C20 2.90575 19.0943 2 18 2H10L8 0H2ZM2 2H7.17188L9.17188 4H18V14H2V2Z"
        fill={color || '#2C2C2C'}
      />
    </svg>
  );
};
