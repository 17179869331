import React from 'react';

const FileSelectIcon = ({ color = '#478FF3' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M56.7311 24.9052V54.2379H26.7873V24.9052H56.7311ZM56.7311 20.7148H26.7873C24.4346 20.7148 22.5096 22.6005 22.5096 24.9052V54.2379C22.5096 56.5426 24.4346 58.4282 26.7873 58.4282H56.7311C59.0838 58.4282 61.0087 56.5426 61.0087 54.2379V24.9052C61.0087 22.6005 59.0838 20.7148 56.7311 20.7148Z"
        fill={color}
      />
      <path
        d="M46.0369 50.0475H31.065V45.8571H46.0369V50.0475ZM52.4534 41.6667H31.065V37.4764H52.4534V41.6667ZM52.4534 33.286H31.065V29.0956H52.4534V33.286Z"
        fill={color}
      />
      <rect x="5.22742" y="7.96851" width="33.3921" height="32.65" fill="white" />
      <path
        d="M36.6394 9.20493V38.6394H7.20493V9.20493H36.6394ZM36.6394 5H7.20493C4.89222 5 3 6.89222 3 9.20493V38.6394C3 40.9521 4.89222 42.8443 7.20493 42.8443H36.6394C38.9521 42.8443 40.8443 40.9521 40.8443 38.6394V9.20493C40.8443 6.89222 38.9521 5 36.6394 5ZM26.4214 23.6278L20.1141 31.7644L15.6148 26.319L9.30739 34.4345H34.537L26.4214 23.6278Z"
        fill={color}
      />
    </svg>
  );
};

export default FileSelectIcon;
