/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuIcon } from '../../icons/MenuIcon';
import Menu from './Menu';
import '../../scss/components/common/menu-button.scss';
import useOutsideClick from '../../hooks/UseOutsideClick';

const MenuButton = () => {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const location = useLocation();

  const handleClickOutside = () => {
    setMenuVisibility(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  useEffect(() => {
    setMenuVisibility(false);
  }, [location]);

  return (
    <div className="menu-button">
      <button ref={ref} onClick={() => setMenuVisibility(!menuVisibility)} aria-label="Open Menu">
        {location.pathname === '/' ||
        location.pathname === '/contact-us' ||
        location.pathname === '/terms-conditions' ? (
          <MenuIcon />
        ) : (
          <MenuIcon color="#FFFFFF" />
        )}
      </button>
      <Menu show={menuVisibility} />
    </div>
  );
};

export default MenuButton;
