import React from 'react';

export const AudioIcon = ({ width, height }) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width || '48'} height={height || '48'} fill="#FFFFFF" />
      <path
        d="M16 4V44H20V4H16ZM40 4V44H44V4H40ZM10 12V36H14V12H10ZM22 12V36H26V12H22ZM34 12V36H38V12H34ZM4 18V30H8V18H4ZM28 18V30H32V18H28Z"
        fill="#8892A4"
      />
    </svg>
  );
};
