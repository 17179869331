import React from 'react';
import '../../../scss/components/transfer/sending-process.scss';
import { useSelector } from 'react-redux';
import Progress from './Progress';
import Preview from './Preview';
import UploadProgress from './UploadProgress';
import TransferDone from './TransferDone';
import { SENDING_STATUS } from '../../../utils/constants';
import Verification from './Verification';

const SendingProcess = ({ errorCallback }) => {
  const { status } = useSelector((state) => state.transfer);

  return (
    <div className="sending-process">
      {status < SENDING_STATUS.SENT && <Progress />}
      {status === SENDING_STATUS.VERIFY_REQUIRED && (
        <Verification
          errorCallback={(text) => {
            errorCallback(text);
          }}
        />
      )}
      {status === SENDING_STATUS.PRE_SUBMIT && (
        <Preview
          errorCallback={(text) => {
            errorCallback(text);
          }}
        />
      )}
      {(status === SENDING_STATUS.SUBMIT || status === SENDING_STATUS.UPLOADING) && <UploadProgress />}
      {status === SENDING_STATUS.SENT && <TransferDone />}
    </div>
  );
};

export default SendingProcess;
