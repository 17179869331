import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import '../../scss/components/common/logout-button.scss';
import '../../scss/components/common/menu.scss';
import UpTriangle from './UpTriangle';

const LogoutButton = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('account');
    localStorage.removeItem('token');
    history.push('/');
  };

  const handleCancelButtonClicked = () => {
    setShow(false);
  };

  return (
    <div>
      <div>
        <p onClick={() => setShow(true)}>Logout</p>
      </div>
      {show && (
        <div className="logout-button">
          <UpTriangle show={show} />
          <Container
            fluid
            className="logout-container"
            style={{
              display: show ? 'flex' : 'none',
            }}
          >
            <div className="content">
              <Row className="text-container">
                <p>
                  <strong>Are you sure you want to log out?</strong>
                </p>
                <p className="text">Your active Session Will Be terminated.</p>
              </Row>
              <Row className="button-container">
                <Col>
                  <Button onClick={handleLogout} className="btn-homepage" aria-label="HomePage">
                    Logout
                  </Button>
                </Col>
                <Col>
                  <Button onClick={handleCancelButtonClicked} className="btn-cancel" aria-label="cancel">
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default LogoutButton;
