import React, { useEffect, useState, useRef } from 'react';
import { setFiles } from '../../store/transfer/actions';
import { store } from '../../store';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';

import { LargePlusIcon } from '../../icons/LargePlusIcon';
import { ButtonPlusLarge } from '../../icons/ButtonPlusLarge';
import { UPLOAD_LIMIT_BY_BYTE_DEFAULT, UPLOAD_LIMIT_BY_BYTE_REGISTERED } from '../../constants';
import Dropzone from '../common/Dropzone';
import { formatBytes, haveAccount, isMobile } from '../../utils';

import { Row, Col } from 'react-bootstrap';
import File from './File';
import { gtagEvent } from '../../utils/analytics';

const PreviewColumn = ({ errorCallback, setShowExitPrompt }) => {
  const bottomRef = useRef(null);

  let { files } = useSelector((state) => state.transfer);
  const [inputValue, setInputValue] = React.useState('');
  const [dropzoneStatus, setDropzoneStatus] = React.useState(false);
  const [errorText, setErrorText] = useState(
    'The files you are trying to select are larger than your current allowed limit (5 gb)',
  );
  const sizeLimit = haveAccount() ? UPLOAD_LIMIT_BY_BYTE_REGISTERED : UPLOAD_LIMIT_BY_BYTE_DEFAULT;

  useEffect(() => {
    if (isMobile()) {
      return;
    }

    const fileNode = bottomRef.current.lastElementChild;
    fileNode.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });

    setShowExitPrompt(files.length > 0);
  }, [files]);

  const validateFile = (file) => {
    for (let f of files) {
      if (f.name === file.name) {
        return false;
      }
    }

    return true;
  };

  const handleDrag = (e) => {
    e.preventDefault();
    setDropzoneStatus(true);
  };

  const handleDrop = function (files) {
    setDropzoneStatus(false);
    const selectedFiles = Array.prototype.slice.call(files);
    gtagEvent({
      action: 'add_files',
      category: 'Transfer',
      label: 'Drag and Drop',
      value: 1,
      method: 'Drag and Drop',
    });

    handleSelectedFiles(selectedFiles);
  };

  const getFileSizeForProgressBar = () => {
    let totalSize = 0;
    for (let file of files) {
      totalSize += file.size;
    }

    const sizeUsagePercent = (totalSize / sizeLimit) * 100;
    const sizeUsage = sizeUsagePercent > 10 ? sizeUsagePercent : 10;
    const sizeOverUsage = !haveAccount() ? (sizeUsage * 4) / 10 : sizeUsage;
    const root = document.documentElement;
    root?.style.setProperty('--files-stats-width', `${sizeUsage}%`);

    root?.style.setProperty('--files-stats-over-width', `${sizeOverUsage}%`);

    useEffect(() => {
      let a = document.body.getElementsByClassName('files-stats');

      const progressWidthRatio = haveAccount() ? 0.95 : 0.87;

      root?.style.setProperty('--files-stats-over-progress-width', `${a[0].clientWidth * progressWidthRatio}px`);
    }, []);

    return `${formatBytes(totalSize)} / ${haveAccount() ? '5 GB' : '2 GB'}`;
  };

  const selectFiles = () => {
    const input = document.getElementById('file-input');
    if (input) {
      input.click();
    }
  };

  const onSelectFiles = (e) => {
    const selectedFiles = Array.prototype.slice.call(e.target.files);
    gtagEvent({
      action: 'add_files',
      category: 'Transfer',
      label: 'File Selection',
      value: 1,
      method: 'file_selection',
    });
    handleSelectedFiles(selectedFiles);
  };

  const handleSelectedFiles = (selectedFiles) => {
    let totalSize = Array.from(selectedFiles).reduce((acc, file) => acc + file.size, 0);
    for (let file of files) {
      totalSize += file.size;
    }

    if (totalSize > sizeLimit) {
      if (!haveAccount()) {
        errorCallback(
          'The files you are trying to select are larger than your current allowed limit (2 gb). \
         Please sign up your account to send larger files.',
        );

        return;
      }
      errorCallback(errorText);
      return;
    }

    selectedFiles.forEach((file) => {
      if (!validateFile(file)) {
        setErrorText("Can't send multiple files with the same name. Please rename your files to be unique.");
        errorCallback(errorText);
        return;
      }

      file.clientGUID = uuid();
      files = [...files, file];
      store.dispatch(setFiles(files));
    });
  };

  return (
    <Col className="preview-col" onDragEnter={handleDrag} md={6} xs={12}>
      {dropzoneStatus && <Dropzone onLeave={() => setDropzoneStatus(false)} onFileDrop={handleDrop} />}
      <Row className="progress-bar">
        {!haveAccount() && <div className="stripe"></div>}
        <div
          className="files-stats"
          style={{
            width: !haveAccount() ? '40%' : '100%',
          }}
        >
          <span className="file-count">
            {files.length} {files.length == 1 ? 'file' : 'files'}
          </span>
          <span className="file-size">{getFileSizeForProgressBar()}</span>
        </div>
        <div className="files-stats-over">
          <div className="files-stats-over-progress">
            <span className="file-count">
              {files.length} {files.length == 1 ? 'file' : 'files'}
            </span>
            <span className="file-size">{getFileSizeForProgressBar()}</span>
          </div>
        </div>
        {!haveAccount() && (
          <Link
            className="sign-up"
            to="signup"
            target="_blank"
            onClick={() => {
              gtagEvent({
                action: 'sign_up_start',
                category: 'Sign Up',
                label: 'Sign Up For More',
                value: 1,
                method: 'sign_up_for_more',
              });
            }}
          >
            <span>Sign up</span> for more
          </Link>
        )}
      </Row>
      <Row className="files" ref={bottomRef}>
        <input
          type="file"
          id="file-input"
          style={{ display: 'none' }}
          multiple="multiple"
          onChange={onSelectFiles}
          value={inputValue}
          onClick={() => setInputValue('')}
        />
        <Col className="file add-more-button" onClick={selectFiles}>
          <LargePlusIcon width="48" height="48" viewBox="0 0 96 96" />
          <h5>ADD MORE</h5>
          <p>You can add up to 2 GB</p>
        </Col>
        {files.map((file, index) => {
          return (
            <Col key={index} className="file">
              <File file={file} index={index} />
            </Col>
          );
        })}
      </Row>
      <div className="add-more-bottom" onClick={selectFiles}>
        <ButtonPlusLarge />
      </div>
    </Col>
  );
};

export default PreviewColumn;
