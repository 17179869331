export const SIGN_MENU_SHOW_TIME_ON_INIT = 2 * 60;

export const TRANSFER_VERIFICATION_CODE_EXPIRATION_TIME = 15 * 60;

export const SENDING_STATUS = {
  CREATED: 0,
  PRE_SUBMIT: 1,
  SUBMIT: 2,
  UPLOADING: 3,
  SENT: 4,
  ERROR: -1,
  VERIFY_REQUIRED: -2,
};
