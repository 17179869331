import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../api';
import { ProfileIconBig } from '../../icons/ProfileIconBig';
import TimeIcon from '../../icons/TimeIcon';
import { ValidationErrorIcon } from '../../icons/ValidationErrorIcon';
import { convertSecondsToTime } from '../../utils/component';
import { TRANSFER_VERIFICATION_CODE_EXPIRATION_TIME } from '../../utils/constants';
import { composeValidators, required, validEmail } from '../../utils/form';
import DownTriangle from '../common/DownTriangle';
import ErrorPopup from '../common/ErrorPopup';
import FormCheckbox from '../common/form/FormCheckbox';
import FormTextField from '../common/form/FormTextField';
import { findColorByName } from './ChangeBackground';
import { useDispatch } from 'react-redux';
import { setAccount } from '../../store/account/actions';

const ChangeInfo = ({ passwordPageClicked, bgColor, setBgColor }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isShowPageChangeConfirm, changeVisibility] = useState(false);
  const unblockHandle = useRef();
  const [tLocation, setTLocation] = useState(undefined);
  const [profile, setProfile] = useState(null);
  const [currentColor, setCurrentColor] = useState(null);
  const [code, setCode] = useState('');
  const [codeExpirationCountdown, setCodeExpirationCountdown] = useState(TRANSFER_VERIFICATION_CODE_EXPIRATION_TIME);
  const [verifyRequired, setVerifyRequired] = useState(false);
  const [showErrorPopup, toggleErrorPopup] = useState(false);
  const [updateToken, setUpdateToken] = useState('');
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    optin: false,
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (codeExpirationCountdown > 0) {
        setCodeExpirationCountdown(codeExpirationCountdown - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [codeExpirationCountdown]);

  const getProfileRequest = async () => {
    try {
      const response = await api.get(`/account`);

      const { data } = response.data;

      const color = findColorByName(data.options?.theme?.primaryColor);
      if (color !== null) {
        setBgColor(color ? color : bgColor);
        setCurrentColor(color ? color : bgColor);
      }

      return data;
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        throw new Error(error.response.data.error.code);
      }

      throw error;
    }
  };

  const updateVerify = async () => {
    try {
      await api.post(`/account/update-verify`, {
        code: code,
        updateToken: updateToken,
      });

      setVerifyRequired(false);
      await getProfile();

      toast.success('Profile Updated', {
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'toast-message',
      });
    } catch (error) {
      if (error?.response?.data?.error?.code == 'invalid_email_code') {
        toggleErrorPopup(true);
        return;
      }

      if (error?.response?.data?.error?.code) {
        throw new Error(error.response.data.error.code);
      }

      throw error;
    }
  };

  const updateProfile = async () => {
    try {
      const response = await api.put(`/account`, {
        email: formValues.email,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        primaryColor: bgColor.name,
        optin: formValues.optin,
      });

      const { data } = response.data;

      if (data.verifyRequired) {
        setVerifyRequired(true);
        setUpdateToken(data.updateToken);
        return;
      }

      toast.success('Profile Updated', {
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'toast-message',
      });

      await getProfile();
      setCurrentColor(bgColor);
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        throw new Error(error.response.data.error.code);
      }

      throw error;
    }
  };

  useEffect(() => {
    unblockHandle.current = history.block((targetLocation) => {
      const element = document.getElementById('btn-save-settings');

      if (unblockHandle.current != false && (element == null || !element.hasAttribute('disabled'))) {
        setTLocation(targetLocation);
        showPageChangeConfirm();
        return false;
      }

      return true;
    });

    return () => unblockHandle.current.current && unblockHandle.current.current();
  });

  useEffect(() => {
    return () => {
      unblockHandle.current = false;
    };
  }, []);

  useEffect(() => {
    const unloadCallback = (event) => {
      const element = document.getElementById('btn-save-settings');
      if (!element.hasAttribute('disabled')) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  const getProfile = async () => {
    const profile = await getProfileRequest();
    setFormValues({
      firstName: profile?.firstName,
      email: profile?.email,
      lastName: profile?.lastName,
      optin: profile?.optin,
    });

    setProfile(profile);

    localStorage.setItem('account', JSON.stringify(profile));

    dispatch(setAccount(profile));
  };

  useEffect(async () => {
    await getProfile();
  }, []);

  const showPageChangeConfirm = () => changeVisibility(true);
  const hidePageChangeConfirm = () => changeVisibility(false);

  function handleConfirm() {
    if (unblockHandle) {
      unblockHandle.current();
    }
    history.push(tLocation);
  }

  const handleNameInputChange = (event) => {
    const { name, value } = event.target;
    const regex = /^[\p{L} ]*$/u;
    if (regex.test(value)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const isFormUpdated = () =>
    profile?.firstName !== formValues.firstName ||
    profile?.lastName !== formValues.lastName ||
    profile?.email !== formValues.email ||
    profile?.optin !== formValues.optin ||
    currentColor?.hex !== bgColor?.hex;

  return (
    <div>
      <ToastContainer />
      <div>
        <ProfileIconBig />
        <h2 className="header">Account details</h2>
      </div>
      <div>
        <Form
          onSubmit={verifyRequired ? updateVerify : updateProfile}
          initialValues={{
            firstName: profile?.firstName,
            lastName: profile?.lastName,
            email: profile?.email,
            optin: profile?.optin,
          }}
          keepDirtyOnReinitialize
          render={({ handleSubmit, submitting }) => (
            <form>
              <Row>
                <Col>
                  <FormTextField
                    formName="firstName"
                    spanText="Name"
                    value={formValues.firstName}
                    validate={required}
                    onChange={handleNameInputChange}
                  />
                </Col>
                <Col>
                  <FormTextField
                    formName="lastName"
                    spanText="Surname"
                    value={formValues.lastName}
                    validate={required}
                    onChange={handleNameInputChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="email-container">
                  <FormTextField
                    formName="email"
                    spanText="Email"
                    value={formValues.email}
                    validate={composeValidators(required, validEmail)}
                    onChange={handleInputChange}
                  />
                  {!profile?.isVerified && (
                    <div className="validation-error verification-required">
                      <div className="validation-text-container">
                        <span>verification required</span>
                      </div>
                      <ValidationErrorIcon />
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="receive-emails-container">
                  <FormCheckbox
                    value={formValues.optin}
                    onChange={() => {
                      setFormValues({ ...formValues, optin: !formValues?.optin });
                    }}
                    formName="optin"
                  />
                  <label className="checkbox-label">Receive news and product updates</label>
                </Col>
              </Row>

              {!verifyRequired ? (
                <Row className="buttons">
                  <Col xs={12} md={6}>
                    <Button className={'btn-change-password'} onClick={() => passwordPageClicked(true)}>
                      <p>Change Password</p>
                    </Button>
                  </Col>
                  <Col xs={12} md={6} className="save-settings-container">
                    <div
                      className="confirm-page-chage-container"
                      style={{
                        display: !isShowPageChangeConfirm ? 'none' : 'flex',
                      }}
                    >
                      <Row>
                        <h3>Are you sure?</h3>
                        <p>You should click on “Save Account Settings” button to save settings.</p>
                      </Row>
                      <Row className="buttons">
                        <Col md={6}>
                          <Button className="btn-cancel-page-change" onClick={handleConfirm}>
                            Yes
                          </Button>
                        </Col>
                        <Col md={6}>
                          <Button className="btn-confirm-page-change" onClick={hidePageChangeConfirm}>
                            Cancel
                          </Button>
                        </Col>
                      </Row>
                    </div>
                    <DownTriangle show={isShowPageChangeConfirm} />
                    <Button
                      type="button"
                      id="btn-save-settings"
                      onClick={handleSubmit}
                      className="btn-save"
                      disabled={!isFormUpdated() || submitting}
                    >
                      <p>Save Account Settings</p>
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row className="verification buttons">
                  <Col className="verification-input col-8">
                    <input
                      type="text"
                      placeholder="Enter the code here"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                    <div className="countdown">
                      <span>{convertSecondsToTime(codeExpirationCountdown)}</span>
                      <TimeIcon />
                    </div>
                  </Col>
                  <Col className="verify-button col-4">
                    <Button type="button" onClick={handleSubmit} className="btn-verify">
                      <p>Verify</p>
                    </Button>
                  </Col>
                </Row>
              )}
            </form>
          )}
        />
        {showErrorPopup && <ErrorPopup text="Invalid Code" actionText="Okey" action={() => toggleErrorPopup(false)} />}
      </div>
    </div>
  );
};

export default ChangeInfo;
