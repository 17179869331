import { haveAccount } from '../../utils';

let initialAccount = haveAccount() ? JSON.parse(localStorage.getItem('account')) : null;

const initialState = {
  files: [],
  status: 0,
  uploadProgress: {},
  transferGUID: '',
  totalSize: 0,
  link: '',
  form: {
    email: initialAccount?.email || '',
    toEmailList: [],
    expire: { duration: 7, keep: false },
    message: '',
    password: '',
  },
  submittedFiles: [],
};

export const transferReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_FILES':
      return {
        ...state,
        files: action.payload.files,
      };
    case 'SET_STATUS':
      return {
        ...state,
        status: action.payload.status,
      };
    case 'SET_FORM':
      return {
        ...state,
        form: action.payload.form,
      };
    case 'SET_UPLOAD_PROGRESS': {
      state.uploadProgress[action.payload.fileClientGUID] = action.payload.uploadProgress;
      return {
        ...state,
      };
    }
    case 'SET_TRANSFER_GUID':
      return {
        ...state,
        transferGUID: action.payload.transferGUID,
      };

    case 'SET_SUBMITTED_FILES':
      return {
        ...state,
        submittedFiles: action.payload.submittedFiles,
      };

    case 'SET_INITIAL_STATE':
      initialAccount = haveAccount() ? JSON.parse(localStorage.getItem('account')) : null;
      return {
        ...initialState,
        form: {
          email: initialAccount?.email || '',
          toEmailList: [],
          expire: { duration: 7, keep: false },
          message: '',
          password: '',
        },
      };
    case 'SET_TOTAL_SIZE':
      return {
        ...state,
        totalSize: action.payload.totalSize,
      };
    case 'SET_LINK':
      return {
        ...state,
        link: action.payload.link,
      };
    default:
      return state;
  }
};
