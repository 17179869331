import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../../scss/pages/profile.scss';
import Background from '../common/Background';
import ChangeBackground from './ChangeBackground';
import FormColumn from './FormColumn';

const Profile = () => {
  const [bgColor, setBgColor] = useState();
  const [passwordPageShown, setPasswordPageShown] = useState(false);

  return (
    <div className="profile">
      <Background />
      <Row className="main-row">
        <Col className={`change-background-container ${passwordPageShown ? 'disabled' : ''}`}>
          <ChangeBackground passwordPageShown={passwordPageShown} setBgColor={setBgColor} bgColor={bgColor} />
        </Col>
        <FormColumn
          handlePasswordShown={(status) => {
            setPasswordPageShown(status);
          }}
          bgColor={bgColor}
          setBgColor={setBgColor}
        />
      </Row>
    </div>
  );
};

export default Profile;
