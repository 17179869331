import React from 'react';

export const InvalidPasswordRequirement = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <g filter="url(#filter0_i_236_5456)">
          <circle cx="6" cy="6" r="6" fill="white" />
        </g>
        <circle cx="6" cy="6" r="5.5" stroke="#D9D9D9" />
      </g>
    </svg>
  );
};
