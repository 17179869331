import React from 'react';

const DownloadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M5.25 1C4.84147 1 4.5 1.34147 4.5 1.75V5H2.29297L6 8.70703L9.70703 5H7.5V1.75C7.5 1.34147 7.15853 1 6.75 1H5.25ZM5.5 2H6.5V6H7.29297L6 7.29297L4.70703 6H5.5V2ZM1 10V11H11V10H1Z"
      fill="#478FF3"
    />
  </svg>
);

export default DownloadIcon;
