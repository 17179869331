import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLink, setStatus, setUploadProgress } from '../../../store/transfer/actions';
import uploadingBar from '../../../assets/images/loading_bar.png';
import OkayIcon from '../../../icons/OkayIcon';
import { SENDING_STATUS } from '../../../utils/constants';
import api from '../../../api';
import axios from 'axios';
import { gtagEvent } from '../../../utils/analytics';

function UploadProgress() {
  const dispatch = useDispatch();
  const transfer = useSelector((state) => state.transfer);
  let { transactionId } = useSelector((state) => state.app);

  const { uploadProgress, status, submittedFiles, totalSize } = transfer;

  const createUploadLink = (transferGUID, file) => async () => {
    try {
      const { data } = await api.post(`/transfers/${transferGUID}/link`, {
        guid: file.guid,
        clientGUID: file.clientGUID,
      });

      return data;
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        return error.response.data.error.code;
      }

      return error;
    }
  };

  const submitTransfer = async () => {
    try {
      let receiverEmails = [];
      transfer.form.toEmailList.forEach((email) => {
        receiverEmails.push(email.email);
      });
      const { data } = await api.put(`/transfers/${transfer.transferGUID}`, {
        transactionId,
        receiverEmails,
        senderEmail: transfer.form.email,
        message: transfer.form.message,
        keepAfterExpire: transfer.form.expire.keep,
        expirationDay: transfer.form.expire.duration,
        password: transfer.form.password,
      });

      dispatch(setLink(data.data.link));

      return data;
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        return error.response.data.error.code;
      }

      return error;
    }
  };

  const uploadFiles = async () => {
    for (const sFile of submittedFiles) {
      const { data } = await dispatch(createUploadLink(transfer.transferGUID, sFile));
      const file = transfer.files.find((file) => file.clientGUID === sFile.clientGUID);

      await axios.put(data.link, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: function (progressEvent) {
          dispatch(setUploadProgress(progressEvent.loaded, sFile.clientGUID));
          if (status === SENDING_STATUS.SUBMIT && getUploadProgress() === 42) {
            dispatch(setStatus(SENDING_STATUS.UPLOADING));
          }
        },
      });
    }
  };

  useEffect(async () => {
    const handleUpload = async () => {
      await uploadFiles();
      await submitTransfer();
      gtagEvent({
        action: 'transfer_done',
        category: 'Transfer',
        label: 'Tranfer Done',
        value: 1,
      });
      dispatch(setStatus(SENDING_STATUS.SENT));
    };

    handleUpload();
  }, [uploadProgress, submittedFiles, dispatch]);

  const getUploadProgress = () => {
    let totalProgress = 0;
    submittedFiles.forEach((sFile) => {
      if (uploadProgress[sFile.clientGUID]) {
        totalProgress += uploadProgress[sFile.clientGUID];
      }
    });

    return Math.round((totalProgress / totalSize) * 100);
  };

  return (
    <div className="upload-progress">
      <div className="progress-container">
        <img className="progress-bar" src={uploadingBar} />
        {uploadProgress == 100 ? (
          <div className="progress-percent">
            <OkayIcon width="96" height="96" color="#478FF3" />
          </div>
        ) : (
          <span className="progress-percent">{`${getUploadProgress()}%`}</span>
        )}
      </div>

      <h1>
        {(() => {
          switch (status) {
            case SENDING_STATUS.UPLOADING:
              return 'Your files are being uploaded to our robust and highly-secured digital storage systems.';
            case SENDING_STATUS.DONE:
              return 'Done!';
            default:
              return 'We are encrypting your files with enhanced methods.';
          }
        })()}
      </h1>
    </div>
  );
}

export default UploadProgress;
