import React from 'react';

export const ButtonRemove = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_56_3853)">
        <circle cx="15" cy="13" r="9.5" fill="white" stroke="#D9D9D9" />
        <path
          d="M10.6026 7.75L9.75 8.60262L14.1474 13L9.75 17.3974L10.6026 18.25L15 13.8526L19.3974 18.25L20.25 17.3974L15.8526 13L20.25 8.60262L19.3974 7.75L15 12.1474L10.6026 7.75Z"
          fill="#2C2C2C"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_56_3853"
          x="0"
          y="0"
          width="30"
          height="30"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_56_3853" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_56_3853" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
