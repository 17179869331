import React from 'react';

export const PasswordIcon = ({ width, height }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '20'}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C4.67619 0 2 2.54875 2 5.71429V6.66667C0.906937 6.66667 0 7.53042 0 8.57143V18.0952C0 19.1363 0.906937 20 2 20H14C15.0931 20 16 19.1363 16 18.0952V8.57143C16 7.53042 15.0931 6.66667 14 6.66667V5.71429C14 2.54875 11.3238 0 8 0ZM8 1.90476C10.2762 1.90476 12 3.54649 12 5.71429V6.66667H4V5.71429C4 3.54649 5.72381 1.90476 8 1.90476ZM2 8.57143H14V18.0952H2V8.57143ZM8 11.4286C6.9 11.4286 6 12.2857 6 13.3333C6 14.381 6.9 15.2381 8 15.2381C9.1 15.2381 10 14.381 10 13.3333C10 12.2857 9.1 11.4286 8 11.4286Z"
        fill="white"
      />
    </svg>
  );
};
