import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RecentTransferIcon } from '../../icons/RecentTransferIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import PeopleIcon from '../../icons/PeopleIcon';
import { store } from '../../store';
import { setTransfer } from '../../store/recentTransfers/actions';
import ReceiverEmails from './ReceiverEmails';
import { format } from 'date-fns';

const Transfer = ({ transfers }) => {
  const [selectedTransfer, setSelectedTransfer] = useState(0);

  return (
    <Col className="transfers-list" md={6} xs={12}>
      <Row className="list-header">
        <Col>
          <div className="icon">
            <CalendarIcon />
          </div>
          <span>Date</span>
        </Col>
        <Col>
          <div className="icon">
            <RecentTransferIcon color="#478FF3" />
          </div>
          <span>Files</span>
        </Col>
        <Col>
          <div className="icon">
            <PeopleIcon />
          </div>
          <span>People</span>
        </Col>
      </Row>
      <Row
        className="items"
        style={{
          flexDirection: transfers.length > 8 ? 'row' : 'column',
        }}
      >
        {transfers.map((transfer, index) => (
          <Row
            className={`list-item ${transfer.isExpired ? 'list-item-expired' : ''} ${
              selectedTransfer == index ? 'list-item-selected' : ''
            }`}
            key={index}
            onClick={() => {
              setSelectedTransfer(index);
              if (!transfer.receiverEmails) {
                transfer.receiverEmails = [];
              }
              store.dispatch(setTransfer(transfer));
            }}
          >
            <Col className="date">{format(new Date(transfer.createdAt), 'dd.MM.yyyy')}</Col>
            <Col className="files">
              {transfer.files.length} {transfer.files.length == 1 ? 'file' : 'files'}
            </Col>
            <Col className="people">
              {transfer.receiverEmails && (
                <ReceiverEmails
                  receiverEmails={transfer.receiverEmails}
                  receiverEmailColors={transfer.receiverEmailColors}
                />
              )}
            </Col>
          </Row>
        ))}
      </Row>
    </Col>
  );
};

export default Transfer;
