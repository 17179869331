import React, { useState, useEffect, useMemo } from 'react';
import '../../scss/components/common/background.scss';
import { useLocation } from 'react-router-dom';
import { themes } from '../../utils/theme';

const Background = ({ backgroundColor }) => {
  let mainTheme = themes[backgroundColor];
  if (!mainTheme) {
    mainTheme = themes['black'];
  }

  const root = document.documentElement;
  root?.style.setProperty('--background-color', mainTheme.primary);
  root?.style.setProperty('--particle-color', mainTheme.secondary);
  root?.style.setProperty('--header-text-color', mainTheme.text);

  const location = useLocation();

  const numParticles = useMemo(() => Math.floor(Math.random() * (8 - 4 + 1) + 4), []);

  const [particles, setParticles] = useState([]);

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname !== '/contact-us' && location.pathname !== '/terms-conditions') {
      let newParticles = [];
      for (let i = 0; i < numParticles; i++) {
        newParticles.push(i);
      }
      setParticles(newParticles);
    } else {
      setParticles([]);
    }
  }, [location.pathname, numParticles]);

  return (
    <div className="background">
      {particles.map((particle, index) => (
        <div key={index} className={`particle ${index % 2 === 0 ? 'particle2' : 'particle1'}`}></div>
      ))}
    </div>
  );
};

export default Background;
