import React from 'react';

const FileEncrytionIcon = ({ color = '#8892A4' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 5.33325C23.1365 5.33325 16 12.1299 16 20.5713V23.111C13.0852 23.111 10.6667 25.4144 10.6667 28.1904V53.5872C10.6667 56.3633 13.0852 58.6666 16 58.6666H48C50.9149 58.6666 53.3334 56.3633 53.3334 53.5872V28.1904C53.3334 25.4144 50.9149 23.111 48 23.111V20.5713C48 12.1299 40.8635 5.33325 32 5.33325ZM32 10.4126C38.0699 10.4126 42.6667 14.7905 42.6667 20.5713V23.111H21.3334V20.5713C21.3334 14.7905 25.9302 10.4126 32 10.4126ZM16 28.1904H48V53.5872H16V28.1904ZM32 35.8094C29.0667 35.8094 26.6667 38.0952 26.6667 40.8888C26.6667 43.6825 29.0667 45.9682 32 45.9682C34.9334 45.9682 37.3334 43.6825 37.3334 40.8888C37.3334 38.0952 34.9334 35.8094 32 35.8094Z"
        fill={color}
      />
    </svg>
  );
};

export default FileEncrytionIcon;
