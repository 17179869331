import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FileSelectIcon from '../../../icons/FileSelectIcon';
import ProgressLine from '../../../icons/ProgressLine';
import FileEncrytionIcon from '../../../icons/FileEncryptionIcon';
import FileUploadingIcon from '../../../icons/FileUploadingIcon';
import FileSentIcon from '../../../icons/FileSentIcon';
import { useSelector } from 'react-redux';
import { SENDING_STATUS } from '../../../utils/constants';

const Progress = () => {
  const { status, uploadProgress } = useSelector((state) => state.transfer);

  return (
    <Row className="progress" md={8}>
      <Col>
        <FileSelectIcon color={status >= SENDING_STATUS.PRE_SUBMIT ? '#478FF3' : '#8892A4'} />
        <span
          style={{
            color: status >= SENDING_STATUS.PRE_SUBMIT ? '#478FF3' : '#8892A4',
          }}
        >
          Confirm
        </span>
      </Col>
      <Col className="progress-line">
        <ProgressLine color={status >= SENDING_STATUS.SUBMIT ? '#478FF3' : '#8892A4'} />
      </Col>
      <Col>
        <FileEncrytionIcon color={status >= SENDING_STATUS.SUBMIT ? '#478FF3' : '#8892A4'} />
        <span
          style={{
            color: status >= SENDING_STATUS.SUBMIT ? '#478FF3' : '#8892A4',
          }}
        >
          Encryption
        </span>
      </Col>
      <Col>
        <ProgressLine color={status >= SENDING_STATUS.UPLOADING ? '#478FF3' : '#8892A4'} />
      </Col>
      <Col>
        <FileUploadingIcon color={status >= SENDING_STATUS.UPLOADING ? '#478FF3' : '#8892A4'} />
        <span
          style={{
            color: status >= SENDING_STATUS.UPLOADING ? '#478FF3' : '#8892A4',
          }}
        >
          Upload
        </span>
      </Col>
      <Col>
        <ProgressLine color={uploadProgress > 92 ? '#478FF3' : '#8892A4'} />
      </Col>
      <Col>
        <FileSentIcon color={uploadProgress > 98 ? '#478FF3' : '#8892A4'} />
        <span
          style={{
            color: uploadProgress > 98 ? '#478FF3' : '#8892A4',
          }}
        >
          Sent
        </span>
      </Col>
    </Row>
  );
};

export default Progress;
