import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import encryption from '../../assets/images/encryption_2.png';
import shareWithFriend from '../../assets/images/share-with-friends.png';
import shareWithMoreSpace from '../../assets/images/share-with-more-space.png';
import userFriendly from '../../assets/images/user-friendly.png';
import { HorizontalBar } from '../../icons/HorizontalBar';
import { store } from '../../store';
import { setLandingPageMinimized, setMaxScroll, setScrollY } from '../../store/scroll/actions';
import { isAtBottom, isAtTop } from '../../utils/scroll';
import '../../scss/components/landing/introduction.scss';

const Introduction = () => {
  const TAB_STATES = {
    STORAGE: 'storage',
    SECURITY: 'security',
    CONTROL: 'control',
  };
  const resizeTolerancePx = 50;

  let { y: scrollY, max: maxScroll } = useSelector((state) => state.scroll);
  const ref = useRef(null);
  const visibleOnInitRef = useRef(null);
  const [activeTab, setActiveTab] = useState(TAB_STATES.STORAGE);

  useEffect(() => {
    const timer = setTimeout(() => {
      const initial = ref.current.clientHeight - visibleOnInitRef.current.clientHeight;
      store.dispatch(setScrollY(initial));
      store.dispatch(setMaxScroll(initial));
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  function handleResize() {
    if (
      maxScroll + resizeTolerancePx > ref.current.clientHeight ||
      maxScroll - resizeTolerancePx < ref.current.clientHeight
    ) {
      const initial = ref.current.clientHeight - visibleOnInitRef.current.clientHeight;
      store.dispatch(setMaxScroll(initial));

      if (scrollY > maxScroll) {
        store.dispatch(setScrollY(initial));
      }
    }
  }

  useEffect(() => {
    if (maxScroll !== undefined) {
      window.addEventListener('resize', handleResize);
    }
  }, [maxScroll]);

  let transform;
  if (scrollY !== 0 || maxScroll != undefined) {
    transform = `translate(0, ${scrollY}px)`;
  }

  return (
    <Container
      ref={ref}
      fluid
      className="introduction"
      style={{
        transform,
        transitionDuration: '1s',
      }}
      onClick={() => {
        if (isAtTop(scrollY, maxScroll + 1)) {
          return;
        }

        let y = scrollY - window.innerHeight;
        if (isAtBottom(y, 0)) {
          y = 0;
        }

        store.dispatch(setLandingPageMinimized(true));
        store.dispatch(setScrollY(y));
      }}
    >
      <Row ref={visibleOnInitRef} lg={8} md={12}>
        <Row className="scroll-bar">
          <HorizontalBar />
        </Row>
        <Row className="intro-title">
          <p>
            <strong>Your data, you and the people you choose.</strong>
          </p>
        </Row>
      </Row>
      <Row className="hidden-area" lg={8}>
        <Row className="detailed-intro">
          <div className="share-with-friend-container">
            <div>
              <img src={shareWithFriend} alt='Simple and Secure File Sharing'/>
            </div>
            <h2>
              Simple and Secure <br /> File Sharing
            </h2>
          </div>
          <p className="hidden-blockchain-text">
            SafeTP provides a seamless and user-friendly file sharing experience with enhanced security features. Easily
            upload, send, and receive files, knowing that your data is protected with advanced encryption and secure
            transfer protocols. Collaborate with confidence and enjoy worry-free file sharing.
          </p>
        </Row>
        <Row className="tabs">
          <Row className="tabs-buttons" lg={12}>
            <Col>
              <TabButton
                active={activeTab === TAB_STATES.STORAGE}
                text="Storage"
                onClick={() => setActiveTab(TAB_STATES.STORAGE)}
              />
            </Col>
            <Col>
              <TabButton
                active={activeTab === TAB_STATES.SECURITY}
                text="Security"
                onClick={() => setActiveTab(TAB_STATES.SECURITY)}
              />
            </Col>
            <Col>
              <TabButton
                active={activeTab === TAB_STATES.CONTROL}
                text="Control"
                onClick={() => setActiveTab(TAB_STATES.CONTROL)}
              />
            </Col>
          </Row>
          <Row className="tabs-container">
            <Tab name={TAB_STATES.STORAGE} currentState={activeTab}>
              <ContentWithImg src={shareWithMoreSpace} alt="Share with more space">
                <h2>Share with more space</h2>
                <p>
                  Experience the freedom of SafeTP&apos;s secure file storage with 2GB of complimentary space for
                  non-registered users. Unlock additional benefits by becoming a member, increasing your storage to 5GB.
                  Safely store, send, and access your files hassle-free, tailored to your needs.
                </p>
              </ContentWithImg>
            </Tab>
            <Tab name={TAB_STATES.SECURITY} currentState={activeTab}>
              <ContentWithImg src={encryption} alt="Encryption of data on the client side at the time of file transfer">
                <h2>Encryption of data on the client side at the time of file transfer</h2>
                <p>
                  Experience peace of mind with SafeTP&apos;s advanced security technology, ensuring that only senders
                  and recipients have access to transferred files. Your data remains protected from unauthorized access,
                  providing you with a secure and confidential file sharing experience.
                </p>
              </ContentWithImg>
            </Tab>
            <Tab name={TAB_STATES.CONTROL} currentState={activeTab}>
              <ContentWithImg src={userFriendly} alt="User friendly file management">
                <h2>User friendly file management</h2>
                <p>
                  Customize your file sharing experience with SafeTP&apos;s user-friendly platform. Create a
                  personalized download page, set password protection, and add expiration dates to ensure secure,
                  individualized file exchanges. Conveniently store and re-download files after each transfer, all under
                  your control.
                </p>
              </ContentWithImg>
            </Tab>
          </Row>
        </Row>
      </Row>
    </Container>
  );
};

const Tab = (props) => {
  const { name, currentState } = props;
  const active = name === currentState;

  return <div className={'tab ' + (active ? 'active' : '')}>{props.children}</div>;
};

const ContentWithImg = (props) => {
  return (
    <Row className="tab-content">
      <Col className="content-html" md={6}>
        {props.children}
      </Col>
      <Col className="content-img" md={6}>
        <img src={props.src} alt={props.alt}/>
      </Col>
    </Row>
  );
};

const TabButton = (props) => {
  const { text, onClick, active } = props;
  return (
    <Button className={'' + (active ? 'active' : '')} onClick={() => onClick()} alt={text}>
      <p>{text}</p>
    </Button>
  );
};

export default Introduction;
