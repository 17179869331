export const setFiles = (files) => {
  return {
    type: 'SET_FILES',
    payload: {
      files,
    },
  };
};

export const setStatus = (status) => {
  return {
    type: 'SET_STATUS',
    payload: {
      status,
    },
  };
};

export const setForm = (form) => {
  return {
    type: 'SET_FORM',
    payload: {
      form,
    },
  };
};

export const setUploadProgress = (uploadProgress, fileClientGUID) => {
  return {
    type: 'SET_UPLOAD_PROGRESS',
    payload: {
      uploadProgress,
      fileClientGUID,
    },
  };
};

export const setInitialState = () => {
  return {
    type: 'SET_INITIAL_STATE',
  };
};

export const setTransferGUID = (transferGUID) => {
  return {
    type: 'SET_TRANSFER_GUID',
    payload: {
      transferGUID,
    },
  };
};

export const setSubmittedFiles = (submittedFiles) => {
  return {
    type: 'SET_SUBMITTED_FILES',
    payload: {
      submittedFiles,
    },
  };
};

export const setTotalSize = (totalSize) => {
  return {
    type: 'SET_TOTAL_SIZE',
    payload: {
      totalSize,
    },
  };
};

export const setLink = (link) => {
  return {
    type: 'SET_LINK',
    payload: {
      link,
    },
  };
};
