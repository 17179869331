import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'react-bootstrap';
import '../../scss/components/common/illustration.scss';
import '../../scss/components/common/view-dot.scss';
import '../../scss/pages/download.scss';
import Background from '../common/Background';
import api from '../../api';
import DownloadColumn from './DownloadColumn';
import PasswordColumn from './PasswordColumn';
import ErrorPopup from '../common/ErrorPopup';
import InfoColumn from './InfoColumn';

const DownloadPage = (props) => {
  const { id } = props.match.params;
  const [loading, setLoading] = React.useState(true);
  const [transfer, setTransfer] = React.useState({});
  const [isProtected, toggleProtection] = React.useState(false);
  const [errorText, setErrorText] = useState('Something went wrong');
  const [errorPopupStatus, setErrorPopupStatus] = useState(false);
  const history = useHistory();

  const fetchTransfer = async () => {
    try {
      const response = await api.get(`/transfers/${id}?page=download`);
      const { data } = response.data;
      toggleProtection(data.isProtected);
      setTransfer(data);

      return data;
    } catch (error) {
      setLoading(true);
      if (error?.response?.data?.error?.code === 'expired') {
        return history.push({ pathname: '../error', state: { type: 'expired_transfer' } });
      }

      return history.push({ pathname: '../error', state: { type: 'transfer_not_found' } });
    }
  };

  const protectedTransfersCallback = (data) => {
    setTransfer(data);
    toggleProtection(!isProtected);
  };

  useEffect(async () => {
    setLoading(true);
    await fetchTransfer();
    setLoading(false);
  }, []);

  if (loading) return null;

  return (
    <div className="download">
      <Background backgroundColor={transfer.backgroundColor} />
      <Row className="main-row">
        <InfoColumn />
        {isProtected ? (
          <PasswordColumn
            id={id}
            transfer={transfer}
            callback={protectedTransfersCallback}
            errorCallback={(text) => {
              if (text) setErrorText(text);
              setErrorPopupStatus(true);
            }}
          />
        ) : (
          <DownloadColumn transfer={transfer} />
        )}
      </Row>
      {errorPopupStatus && (
        <ErrorPopup text={errorText} actionText="Try Again" action={() => setErrorPopupStatus(false)} />
      )}
    </div>
  );
};

export default DownloadPage;
