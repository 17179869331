import React from 'react';
import { Field } from 'react-final-form';

export const FormHiddenField = ({ formName, validate, onChange, value }) => {
  return (
    <Field name={formName} validate={validate}>
      {({ input }) => (
        <div className="custom-form-group" style={{ display: 'none' }}>
          <input
            type="hidden"
            className="text"
            value={value}
            {...input}
            onChange={(e) => {
              input.onChange(e);

              if (onChange) {
                onChange(e);
              }
            }}
          />
        </div>
      )}
    </Field>
  );
};
