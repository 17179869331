import React from 'react';
import { FileIcon } from '../../icons/fileIcons/FileIcon';
import { VideoIcon } from '../../icons/fileIcons/VideoIcon';
import { ImageIcon } from '../../icons/fileIcons/ImageIcon';
import { AudioIcon } from '../../icons/fileIcons/AudioIcon';
import { PptIcon } from '../../icons/fileIcons/PptIcon';
import { PdfIcon } from '../../icons/fileIcons/PdfIcon';
import { DocIcon } from '../../icons/fileIcons/DocIcon';
import { XlsIcon } from '../../icons/fileIcons/XlsIcon';
import { MIME_TYPES } from '../../constants';

const FilePreview = ({ file, width, height, imagePreview = true }) => {
  const mimeTypeGroup = file.type && file.type.split('/')[0];

  switch (mimeTypeGroup) {
    case MIME_TYPES.IMAGE:
      if (imagePreview) return <img src={URL.createObjectURL(file)} />;

      return (
        <div className="file-icon">
          {' '}
          <ImageIcon width={width || '48'} height={height || '48'} />
        </div>
      );

    case MIME_TYPES.VIDEO:
      return (
        <div className="file-icon">
          {' '}
          <VideoIcon width={width || '48'} height={height || '48'} />
        </div>
      );
    case MIME_TYPES.AUDIO:
      return (
        <div className="file-icon">
          {' '}
          <AudioIcon width={width || '48'} height={height || '48'} />
        </div>
      );
  }

  switch (file.type) {
    case MIME_TYPES.PDF:
      return (
        <div className="file-icon">
          {' '}
          <PdfIcon width={width || '48'} height={height || '48'} />
        </div>
      );
    case MIME_TYPES.DOC:
    case MIME_TYPES.DOCX:
      return (
        <div className="file-icon">
          {' '}
          <DocIcon width={width || '48'} height={height || '48'} />
        </div>
      );
    case MIME_TYPES.XLS:
    case MIME_TYPES.XLSX:
      return (
        <div className="file-icon">
          {' '}
          <XlsIcon width={width || '48'} height={height || '48'} />
        </div>
      );
    case MIME_TYPES.PPT:
    case MIME_TYPES.PPTX:
      return (
        <div className="file-icon">
          {' '}
          <PptIcon width={width || '48'} height={height || '48'} />
        </div>
      );
    default:
      return (
        <div className="file-icon">
          {' '}
          <FileIcon width={width || '48'} height={height || '48'} />
        </div>
      );
  }
};

export default FilePreview;
