import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import '../../scss/pages/error.scss';
import expiredTransferIllustration from '../../assets/illustrations/transfer_is_expired.png';
import transferNotFoundIllustration from '../../assets/illustrations/transfer_not_found.png';
import pageNotFoundIllustration from '../../assets/illustrations/page_not_found.png';
import Background from '../common/Background';

const ErrorPage = ({ location }) => {
  const type = location.state?.type;
  const renderSwitch = () => {
    switch (type) {
      case 'expired_transfer':
        return (
          <div>
            <img src={expiredTransferIllustration} />
            <h1>This transfer is expired</h1>
            <p>You are a little late.</p>
          </div>
        );
      case 'transfer_not_found':
        return (
          <div>
            <img src={transferNotFoundIllustration} />
            <h1>Transfer not found</h1>
            <p>We really searched everywhere.</p>
          </div>
        );

      default:
        return (
          <div>
            <img src={pageNotFoundIllustration} />
            <h1>You flew into a wrong place</h1>
          </div>
        );
    }
  };

  return (
    <Container className="error">
      <Background />
      {renderSwitch()}
      <Row className="buttons">
        {type != 'expired_transfer' && (
          <Col>
            <Button
              className="button contact-us-button"
              onClick={() => {
                window.location.href = '/contact-us';
              }}
            >
              Contact Us
            </Button>
          </Col>
        )}
        <Col>
          <Button
            className="button homepage-button"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Homepage
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;
