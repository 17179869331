import React from 'react';
import { Col, Navbar, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import '../../scss/components/common/header.scss';
import MenuButton from './MenuButton';
import ProfileButton from './ProfileButton';
import { SIGN_MENU_SHOW_TIME_ON_INIT } from '../../utils/constants';
import LogoButton from './LogoButton';
import AccountHeader from './AccountHeader';
import { useSelector } from 'react-redux';
import { haveAccount } from '../../utils';

const Header = () => {
  const location = useLocation();
  let { minimized } = useSelector((state) => state.scroll);

  return (
    <Navbar className={`${minimized ? 'minimized' : 'nminimized'}`}>
      <Row>
        {location.pathname === '/' ? null : (
          <Col className="header-logo">
            <LogoButton />
          </Col>
        )}
        <Col>
          <Row className="menu-container">
            <Col className="account-container">
              {!haveAccount() ? <ProfileButton showTime={SIGN_MENU_SHOW_TIME_ON_INIT} /> : <AccountHeader />}
            </Col>
            <Col className="menu-button-container">
              <MenuButton />
            </Col>
          </Row>
        </Col>
      </Row>
    </Navbar>
  );
};

export default Header;
