import React from 'react';
import '../../scss/components/common/up-triangle.scss';

const UpTriangle = (props) => {
  const { show } = props;

  return (
    <div
      className="up-triangle"
      style={{
        display: show ? 'block' : 'none',
      }}
    ></div>
  );
};

export default UpTriangle;
