export const required = (value) => (value ? undefined : 'Required');

export const isInList = (item, array) => (!array.includes(item) ? undefined : `${item} currently exists`);

export const validEmail = (value) => {
  const email = String(value)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  return email ? undefined : 'Please enter a valid email';
};

export const composeValidators = (...validators) => {
  return (value) => {
    return validators.reduce((error, validator) => error || validator(value), undefined);
  };
};

export const isValidationErr = (meta) =>
  !meta.modifiedSinceLastSubmit &&
  (meta.override || meta.submitFailed) &&
  (meta.error || meta.submitError) &&
  meta.touched;

export const disableEnter = (e) => {
  if (e.key && e.key === 'Enter') {
    e.preventDefault();
    return false;
  }
};

export const hasDuplicates = (arr) => {
  let item;
  arr.some((element) => {
    if (arr.indexOf(element) !== arr.lastIndexOf(element)) {
      item = element;
    }
  });

  return item ? `${item} currently exists` : undefined;
};

export const sameEmail = (value, allValues) => {
  return value === allValues.email ? undefined : 'The emails do not match';
};

export const samePassword = (value, allValues) => {
  return value === allValues.password ? undefined : 'The passwords do not match';
};

export const shouldSame = (value, params) => (value === params.compareValue ? undefined : params.text);

export const isOneOfFieldUpdated = (fields) => Object.values(fields).includes(true);

export const validPassword = (value) => {
  const password = String(value).match(
    // eslint-disable-next-line max-len
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[*.#$%&*+,./:!;<=>?()@[^_`{|}~])[0-9a-zA-Z#$%&*+,./:!;<=>?()@[^_`{|}~]{8,}$/,
  );

  return password
    ? undefined
    : `Password must contain at least 8 characters,
        one uppercase,
        one lowercase and
        one special character`;
};

export const hasUpperCase = (value) => value != null && String(value).match(/(?=.*[A-Z])/) !== null;

export const hasLowerCase = (value) => value != null && String(value).match(/(?=.*[a-z])/) !== null;

export const hasNumber = (value) => value != null && String(value).match(/(?=.*\d)/) !== null;

export const hasSpecialCharacter = (value) => value != null && /[*.#$%&*+,./:!;<=>?()@[\]^_`{|}~-]/.test(String(value));

export const lengthGreaterThan = (value, length) => value != null && value.length > length;

export const termsAndConditions = (value) => {
  return value ? undefined : 'Please accept the terms and conditions';
};
