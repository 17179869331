import React, { useState } from 'react';
import { Field } from 'react-final-form';

const FormTextArea = ({ formName, spanText, validate, onChange, value, placeholder, maxLength }) => {
  const [letterCount, setLetterCount] = useState(0);
  return (
    <Field name={formName} validate={validate} value={value}>
      {({ input }) => (
        <div className="custom-form-group">
          <span className="input-text">{spanText}</span>
          <textarea
            type={'textbox'}
            rows="4"
            cols="50"
            maxLength={maxLength}
            placeholder={placeholder}
            {...input}
            value={value === null || value === undefined ? input.value : value}
            onChange={(e) => {
              if (e.target.value.length > maxLength) {
                e.preventDefault();
                return;
              }

              setLetterCount(e.target.value.length);
              input.onChange(e);

              if (onChange) {
                onChange(e);
              }
            }}
          ></textarea>
          <span className="letter-count">{letterCount}/1000</span>
        </div>
      )}
    </Field>
  );
};

export default FormTextArea;
