import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import ChangeInfo from './ChangeInfo';
import ChangePassword from './ChangePassword';

const FormColumn = ({ handlePasswordShown, bgColor, setBgColor }) => {
  const [passwordPage, setPasswordPage] = useState(false);

  return (
    <Col className="profile-col">
      {passwordPage ? (
        <ChangePassword
          handleBack={() => {
            setPasswordPage(false);
            handlePasswordShown(false);
          }}
        />
      ) : (
        <ChangeInfo
          passwordPageClicked={(clicked) => {
            setPasswordPage(clicked);
            handlePasswordShown(clicked);
          }}
          bgColor={bgColor}
          setBgColor={setBgColor}
        />
      )}
    </Col>
  );
};

export default FormColumn;
