import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import FormTextField from '../common/form/FormTextField';
import { useHistory } from 'react-router-dom';

import { PasswordIcon } from '../../icons/PasswordIcon';
import {
  composeValidators,
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCase,
  lengthGreaterThan,
  required,
  samePassword,
  validPassword,
} from '../../utils/form';
import Requirement from '../common/Requirement';
import api from '../../api';
import { ValidationErrorIcon } from '../../icons/ValidationErrorIcon';
import OkayIcon from '../../icons/OkayIcon';

const FormColumn = ({ token }) => {
  const history = useHistory();

  const [passwords, setPasswords] = useState({
    password: null,
    verify: null,
  });

  const [columnStatus, setColumnStatus] = useState({
    status: 'change-form',
    message: '',
  });

  const changePasswordRequest = async () => {
    try {
      const response = await api.put(`/auth/password/reset`, {
        token,
        password: passwords.password,
      });

      const { data } = response.data;

      return data;
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        throw new Error(error.response.data.error.code);
      }

      throw error;
    }
  };

  const changePassword = async () => {
    try {
      await changePasswordRequest();
      setColumnStatus({
        status: 'success',
        message: 'Password changed successfully.',
      });
    } catch (error) {
      if (error.message === 'invalid_token') {
        setColumnStatus({
          status: 'error',
          message: 'Your token is invalid.',
        });
        return;
      }

      setColumnStatus({
        status: 'error',
        message: 'Something went wrong. Please try again.',
      });
      return;
    }
  };

  switch (columnStatus.status) {
    case 'change-form':
      return (
        <Col className="reset-password-col" md={6} xs={12}>
          <Row className="header">
            <Col>
              <PasswordIcon />
              <h4>Reset Password</h4>
            </Col>
          </Row>

          <Form
            onSubmit={changePassword}
            keepDirtyOnReinitialize={true}
            render={({ handleSubmit }) => (
              <form>
                <Row>
                  <Col md={6}>
                    <FormTextField
                      formName="password"
                      type={'password'}
                      spanText="New Password"
                      onChange={(e) => setPasswords({ ...passwords, password: e.target.value })}
                      validate={composeValidators(required, validPassword)}
                    />
                  </Col>
                  <Col md={6}>
                    <FormTextField
                      formName="new-password-verify"
                      type={'password'}
                      spanText="Verify New Password"
                      onChange={(e) => setPasswords({ ...passwords, verify: e.target.value })}
                      validate={passwords.password != '' ? samePassword : required}
                    />
                  </Col>
                </Row>
                <Row className="password-requirements-container">
                  <Row>
                    <Col md={6}>
                      <Requirement text={'One lowercase character'} rule={() => hasLowerCase(passwords.password)} />
                    </Col>
                    <Col md={6}>
                      <Requirement
                        text={'One special character'}
                        rule={() => hasSpecialCharacter(passwords.password)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Requirement text={'One uppercase character'} rule={() => hasUpperCase(passwords.password)} />
                    </Col>
                    <Col md={6}>
                      <Requirement
                        text={'8 characters minimum'}
                        rule={() => lengthGreaterThan(passwords.password, 8)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Requirement text={'One number'} rule={() => hasNumber(passwords.password)} />
                    </Col>
                  </Row>
                </Row>
                <Row className="button-container">
                  <Col md={12}>
                    <Col md={6}></Col>
                    <Col md={6} xs={12}>
                      <Button type="button" onClick={handleSubmit} className="btn-save-password">
                        <p>Change Password</p>
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </form>
            )}
          />
        </Col>
      );
    case 'error':
      return (
        <Col className="reset-password-info-col">
          <ValidationErrorIcon width={100} height={80} color={'#FFFFFF'} viewBox={'0 0 22 22'} />
          <p style={{ fontSize: '80px', fontWeight: '700', marginBottom: 0 }}>OOPS</p>
          <h1
            style={{
              marginTop: '0',
            }}
          >
            Reset Password
          </h1>
          <p>Link Expired</p>
          <p>{columnStatus.message}</p>
          <Button
            onClick={() => {
              history.push('/signin');
            }}
            style={{
              marginTop: '20px',
            }}
          >
            Sign In
          </Button>
        </Col>
      );
    case 'success':
      return (
        <Col className="reset-password-info-col">
          <OkayIcon width={100} height={80} color={'#FFFFFF'} />
          <h1>Your Password Is Changed</h1>
          <Button
            onClick={() => {
              history.push('/signin');
            }}
            style={{
              marginTop: '20px',
            }}
          >
            Sign In
          </Button>
        </Col>
      );
    default:
      return null;
  }
};

export default FormColumn;
