import React, { useEffect, useState } from 'react';
import TimeIcon from '../../../icons/TimeIcon';
import { Button, Col, Row } from 'react-bootstrap';
import { SENDING_STATUS, TRANSFER_VERIFICATION_CODE_EXPIRATION_TIME } from '../../../utils/constants';
import { convertSecondsToTime } from '../../../utils/component';
import { store } from '../../../store';
import { setStatus, setSubmittedFiles, setTotalSize } from '../../../store/transfer/actions';
import api from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { gtagEvent } from '../../../utils/analytics';

function Verification({ errorCallback }) {
  const dispatch = useDispatch();
  let [codeExpirationCountdown, setCodeExpirationCountdown] = useState(TRANSFER_VERIFICATION_CODE_EXPIRATION_TIME);
  let [code, setCode] = useState('');
  let [resendButtonCountdown, setResendButtonCountdown] = useState(60);
  let [resendButtonDisabled, setResendButtonDisabled] = useState(true);
  const { files, transferGUID, form } = useSelector((state) => state.transfer);
  const { transactionId } = useSelector((state) => state.app);

  const resendCodeRequest = () => async () => {
    try {
      const { data } = await api.post(`/transfers/${transferGUID}/resend-email`);

      return data;
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        throw new Error(error.response.data.error.code);
      }

      throw error;
    }
  };

  const verifyEmailRequest = () => async () => {
    try {
      const { data } = await api.put(`/transfers/${transferGUID}/verify`, {
        email: form.email,
        transactionId,
        code,
      });

      return data;
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        throw new Error(error.response.data.error.code);
      }

      throw error;
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (codeExpirationCountdown > 0) {
        setCodeExpirationCountdown(codeExpirationCountdown - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);

    const intervalId2 = setInterval(() => {
      if (resendButtonCountdown > 0 && !resendButtonDisabled) {
        setResendButtonCountdown(resendButtonCountdown - 1);
      } else if (resendButtonCountdown === 0 && !resendButtonDisabled) {
        setResendButtonCountdown(60);
        setResendButtonDisabled(!resendButtonDisabled);
        clearInterval(intervalId2);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);
    };
  }, [codeExpirationCountdown, resendButtonCountdown, resendButtonDisabled]);

  const resendCode = async () => {
    try {
      await dispatch(resendCodeRequest());
      gtagEvent({
        action: 'resend_verify_transfer_email',
        category: 'Transfer',
        label: 'Resend Verify Transfer Email',
        value: 1,
      });
      setCodeExpirationCountdown(TRANSFER_VERIFICATION_CODE_EXPIRATION_TIME);
      setResendButtonCountdown(60);
      setResendButtonDisabled(!resendButtonDisabled);
    } catch (error) {
      if (error.message === 'verify_transfer_email_limit_exceeded') {
        errorCallback('You reached maximum email limit please try again later');
        return;
      }

      errorCallback('Something went wrong');
    }
  };

  const sendFiles = async (data) => {
    store.dispatch(setSubmittedFiles(data.files));

    let totalSize = 0;
    files.forEach((file) => {
      totalSize += file.size;
    });

    store.dispatch(setTotalSize(totalSize));
    gtagEvent({
      action: 'upload_start',
      category: 'Transfer',
      label: 'Upload Start',
      value: 1,
    });
    store.dispatch(setStatus(SENDING_STATUS.SUBMIT));
  };

  const verifyEmail = async () => {
    try {
      const { data } = await dispatch(verifyEmailRequest());
      gtagEvent({
        action: 'transfer_email_verified',
        category: 'Transfer',
        label: 'Transfer Email Verified',
        value: 1,
      });
      await sendFiles(data);
    } catch (error) {
      if (error.message === 'invalid_transfer_verification_code') {
        errorCallback('Invalid verification code');
        return;
      }

      errorCallback('Something went wrong');
    }
  };

  return (
    <div className="verification">
      <h1>Verification Code</h1>
      <p>We just sent an email with the verification code. You need to enter the code since you are not signed up.</p>
      <div className="verification-input">
        <input
          type="text"
          placeholder="Enter the code here"
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
        <div className="countdown">
          <span>{convertSecondsToTime(codeExpirationCountdown)}</span>
          <TimeIcon />
        </div>
      </div>
      <Row className="buttons">
        <Col className="p-2" md={4} xs={12}>
          <Button
            className="button back-button"
            onClick={() => {
              store.dispatch(setStatus(SENDING_STATUS.PRE_SUBMIT));
            }}
          >
            Back
          </Button>
        </Col>
        <Col className="p-2 resend-button-col" md={4} xs={12}>
          <Button
            className="button resend-button"
            onClick={resendCode}
            disabled={!resendButtonDisabled}
            style={{
              textAlign: !resendButtonDisabled ? 'left' : 'center',
            }}
          >
            Resend the Code
          </Button>
          {!resendButtonDisabled && (
            <div className="countdown">
              <span>{convertSecondsToTime(resendButtonCountdown)}</span>
              <TimeIcon />
            </div>
          )}
        </Col>
        <Col className="p-2" md={4} xs={12}>
          <Button className="button next-button" onClick={verifyEmail}>
            Verify
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default Verification;
