export const hideCookiePopup = () => ({
  type: 'SET_VISIBLE',
  payload: {
    visible: false,
  },
});

export const showCookiePopup = () => ({
  type: 'SET_VISIBLE',
  payload: { visible: true },
});
