import React from 'react';

export const ProfileIcon = ({ color }) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M24 4C12.954 4 4 12.954 4 24C4 35.046 12.954 44 24 44C35.046 44 44 35.046 44 24C44 12.954 35.046 4 24 4ZM24 8C32.836 8 40 15.164 40 24C40 27.1946 39.0508 30.1622 37.4375 32.6602L35.8984 31.4688C32.7944 29.0747 27.074 28 24 28C20.926 28 15.2036 29.0747 12.1016 31.4688L10.5625 32.6641C8.94809 30.1655 8 27.1958 8 24C8 15.164 15.164 8 24 8ZM24 11.5C20.416 11.5 17.5 14.416 17.5 18C17.5 21.584 20.416 24.5 24 24.5C27.584 24.5 30.5 21.584 30.5 18C30.5 14.416 27.584 11.5 24 11.5ZM24 15.5C25.378 15.5 26.5 16.622 26.5 18C26.5 19.378 25.378 20.5 24 20.5C22.622 20.5 21.5 19.378 21.5 18C21.5 16.622 22.622 15.5 24 15.5ZM24 32C30.2014 32 33.5362 34.337 35.0977 35.5078C32.22 38.2837 28.3137 40 24 40C19.6863 40 15.78 38.2837 12.9023 35.5078C14.4638 34.337 17.7986 32 24 32ZM12.1094 34.6797C12.3513 34.9463 12.5945 35.2117 12.8516 35.4609C12.5942 35.2106 12.3495 34.9468 12.1094 34.6797ZM35.8242 34.75C35.6049 34.9911 35.3859 35.2336 35.1523 35.4609C35.3852 35.235 35.6029 34.99 35.8242 34.75Z"
        fill={color || '#8892A4'}
      />
    </svg>
  );
};
