import React, { useEffect } from 'react';
import '../../scss/pages/recenttransfers.scss';
import { Row } from 'react-bootstrap';
import { haveAccount, randomColor } from '../../utils';
import Transfers from './Transfers';
import TransferDetail from './TransferDetail';
import { store } from '../../store';
import { setTransfer } from '../../store/recentTransfers/actions';
import api from '../../api';
import Background from '../common/Background';
const RecentTransfers = () => {
  if (!haveAccount()) {
    window.location.href = '/';
  }
  const [transferList, setTransferList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const fetchRecentTransfers = async () => {
    try {
      const response = await api.get('/transfers/');
      const { data } = response.data;
      setTransferList(data);

      return data;
    } catch (error) {
      console.error('Failed to fetch transfers:', error);
      setLoading(false);
    }
  };

  useEffect(async () => {
    setLoading(true);
    await fetchRecentTransfers();
    setLoading(false);
  }, []);

  const deleteTransfer = async (transfer) => {
    try {
      await api.delete(`/transfers/${transfer.guid}`);
      setLoading(true);
      await fetchRecentTransfers();
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch transfers:', error);
    }
  };

  if (!loading) {
    let transfers = [];
    transfers = transferList.map((transfer) => {
      let colorArray = [];
      for (let j = 0; transfer.receiverEmails && j < transfer.receiverEmails.length; j++) {
        colorArray.push(randomColor());
      }

      transfer.receiverEmailColors = colorArray;
      return transfer;
    });

    store.dispatch(setTransfer(transfers[0]));

    return (
      <div className="recent-transfers">
        <Background />
        <div className="content">
          <span className="title">Recent Transfers</span>
          <Row className="main-row">
            <Transfers transfers={transfers} />
            <TransferDetail deleteTransfer={deleteTransfer} />
          </Row>
        </div>
      </div>
    );
  }

  return null;
};

export default RecentTransfers;
