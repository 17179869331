import React from 'react';

export const SmallInfoIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 1.25C4.04813 1.25 1.25 4.04813 1.25 7.5C1.25 10.9519 4.04813 13.75 7.5 13.75C10.9519 13.75 13.75 10.9519 13.75 7.5C13.75 4.04813 10.9519 1.25 7.5 1.25ZM8.125 10.625H6.875V6.875H8.125V10.625ZM8.125 5.625H6.875V4.375H8.125V5.625Z"
        fill="white"
      />
    </svg>
  );
};
