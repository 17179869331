const initialState = {
  transfer: {
    files: [],
    receiverEmails: [],
  },
};

export const recentTransfersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TRANSFER':
      return {
        ...state,
        transfer: action.payload.transfer,
      };
    default:
      return state;
  }
};
