import React, { useEffect, useState } from 'react';
import '../../scss/pages/verify.scss';
import { Button, Col, Container, Row } from 'react-bootstrap';
import IllustrationInfo from '../common/IllustrationInfo';
import { MyGroupIcon } from '../../icons/MyGroupIcon';
import OkayIcon from '../../icons/OkayIcon';
import { ValidationErrorIcon } from '../../icons/ValidationErrorIcon';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setToken, setAccount } from '../../store/account/actions';
import api from '../../api';
import { haveAccount } from '../../utils';
import Background from '../common/Background';
import { gtagEvent } from '../../utils/analytics';

const Verify = ({ location }) => {
  const history = useHistory();
  if (haveAccount()) {
    window.location.href = '/';
  }

  const dispatch = useDispatch();
  let { transactionId } = useSelector((state) => state.app);
  const email = location.state?.email;
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  let [resendButtonDisabled, setResendButtonDisabled] = useState(true);

  const verifyRequest = () => async () => {
    try {
      const response = await api.put(`/auth/verify`, {
        token,
        transactionId,
      });

      const { data } = response.data;

      return data;
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        throw new Error(error.response.data.error.code);
      }

      throw error;
    }
  };

  useEffect(async () => {
    if (token) {
      try {
        const data = await dispatch(verifyRequest());
        localStorage.setItem('token', data.token);
        dispatch(setToken(data.token));
        localStorage.setItem('account', JSON.stringify(data.account));
        dispatch(setAccount(data.account));
        gtagEvent({
          action: 'account_verified',
          category: 'Sign Up',
          label: 'Account Verified',
          value: 1,
        });
        setIsEmailVerified(true);
      } catch (error) {
        if (error.message === 'account_already_verified') {
          setIsEmailVerified(true);
        }

        if (error.message !== 'invalid_email_token') {
          // TODO do something
        }
      }
    }
  }, [token]);

  const resendCodeRequest = () => async () => {
    try {
      const { data } = await api.post(`/auth/resend-verification`, {
        email,
      });

      gtagEvent({
        action: 'resend_verify_email',
        category: 'Sign Up',
        label: 'Resend Verify Email',
        value: 1,
      });

      return data;
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        throw new Error(error.response.data.error.code);
      }

      throw error;
    }
  };

  const resendEmail = async () => {
    try {
      await dispatch(resendCodeRequest());
      setResendButtonDisabled(!resendButtonDisabled);
    } catch (error) {
      // errorCallback('Something went wrong');
    }
  };

  return (
    <div className="verify">
      <Background />
      <Container>
        <Row className="main-row">
          <Col className="illustration-col">
            <IllustrationInfo
              header="Welcome to SafeTP!"
              text="Verify your account for secure file transfers. 
              Experience worry-free sharing with end-to-end encryption. 
              Thank you for choosing SafeTP."
            />
          </Col>
          <Col className="verify-column">
            {email ? (
              <div>
                <MyGroupIcon width={100} height={80} color={'#FFFFFF'} />
                <h1>Verify Email</h1>
                <p>You have to verify the email we sent to {email}</p>
                <Button disabled={!resendButtonDisabled} onClick={resendEmail}>
                  Resend
                </Button>
              </div>
            ) : token && isEmailVerified ? (
              <div>
                <OkayIcon width={100} height={80} color={'#FFFFFF'} />
                <h1>Email Verified</h1>
                <p>Welcome to the SafeTP family!</p>
                <Button
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  Go to Homepage
                </Button>
              </div>
            ) : token && !isEmailVerified ? (
              <div>
                <ValidationErrorIcon width={100} height={80} color={'#FFFFFF'} viewBox={'0 0 22 22'} />
                <p style={{ fontSize: '80px', fontWeight: '700', marginBottom: 0 }}>OOPS</p>
                <h1
                  style={{
                    marginTop: '0',
                  }}
                >
                  Verify Email
                </h1>
                <p>Link Expired</p>
                <p>Sign In and resend your verification email.</p>
                <Button
                  onClick={() => {
                    history.push('/signin');
                  }}
                  disabled={!resendButtonDisabled}
                  style={{
                    marginTop: '20px',
                  }}
                >
                  Sign In
                </Button>
              </div>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Verify;
