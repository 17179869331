import React from 'react';

export const TermsIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C4.477 0 0 4.477 0 10C0 15.523 4.477 20 10 20C15.523 20 20 15.523 20 10C20 4.477 15.523 0 10 0ZM8 15.414L3.293 10.707L4.707 9.293L8 12.586L15.293 5.293L16.707 6.707L8 15.414Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};
