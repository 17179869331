export const setAccount = (account) => {
  return {
    type: 'SET_ACCOUNT',
    payload: {
      account,
    },
  };
};

export const setToken = (token) => {
  return {
    type: 'SET_TOKEN',
    payload: {
      token,
    },
  };
};
