import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import terms from '../../assets/images/terms.png';
import '../../scss/pages/terms.scss';

const TermsPage = () => {
  useEffect(() => {
    const prevColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = '#fff';
    document.body.style.overflow = 'auto';

    return () => {
      document.body.style.backgroundColor = prevColor;
      document.body.style.overflow = 'hidden';
    };
  }, []);

  return (
    <div className="terms">
      <Container>
        <Row>
          <Col md={6} className="terms-container">
            <h1>Terms & Conditions</h1>
            <div>
              <h2>1. Applicability</h2>
              <ul>
                <li> You are only allowed to use the Services when aged 17 or older.</li>
                <li>
                  By using these services, you are deemed to have accepted our Terms of Use, so please read the terms of
                  use carefully.
                </li>
                <li>
                  If the Services include, are used in connection with, or are integrated in the services of third
                  parties, the terms and conditions, notice and take down policies and/or privacy and cookie policies of
                  those third parties may apply in addition to these Terms. If you are using the Services on behalf of
                  your employer or another organization, you are agreeing to the terms of that organization and you
                  represent and warrant that you have the authority to do so. SafeTP is not responsible for any third
                  party services, terms and/or policies.
                </li>
                <li>
                  If you notice a vulnerability in any of the Services, please read our Responsible Disclosure Policy.
                </li>
                <li>
                  SafeTP can amend the Terms from time to time. The amended Terms will become effective upon them being
                  posted on SafeTP website at such later date as may be stated on the amended Terms. Therefore, we
                  recommend that you review the Terms from time to time and take note of any changes. By continuing your
                  use of the Services you accept the amended Terms. In case of material changes to the Terms, you will
                  be informed prior to the change: (i) at the moment you use the Services, or (ii) by a message to the
                  contact details you provided to us, or (iii) by a posting of the notice of the change on SafeTP’s
                  website(s) and/or on SafeTP’s mobile and/or desktop application(s). In the event you don’t accept a
                  change you can cancel your subscription.
                </li>
                <li>
                  These Terms supersede any and all prior oral and written quotations, terms, communications, agreements
                  and understandings between you and SafeTP.
                </li>
              </ul>
              <h2>2. SafeTP File Sharing</h2>
              <ul>
                <li>SafeTP File Sharing allows you to share your Content with others.</li>
                <li>
                  To share your Content you need to upload it and provide us with email addresses of recipient(s) or
                  choose to distribute a download link yourself. If you use link transfer you will not be informed of
                  any downloads by others.
                </li>
                <li>
                  SafeTP’s servers for a limited period of time after which the ability to download expires. Depending
                  on the transfer type some limitations apply
                </li>
                <li>
                  After the expiry period SafeTP will permanently delete the uploaded files. These files will not be
                  available nor retrievable anymore.
                </li>
                <li>The basic functionality of SafeTP file sharing is free from registration and charge.</li>
                <li>
                  SafeTP treats Content as confidential and does not control the use of download links, regardless of
                  whether they are originally distributed by us or by you. Recipients can forward the links and allow
                  others to use them. You are solely responsible for the Content you upload and share.
                </li>
                <li>
                  In SafeTP file sharing, you can set a password if you wish, and transmit your files securely. The
                  protection of passwords is your responsibility.
                </li>
              </ul>
            </div>
          </Col>
          <Col md={5} className="image-container">
            <Row>
              <img src={terms} />
            </Row>
            <Row className="btn-redirect-container">
              <Link to={'/'} className="btn-homepage">
                HomePage
              </Link>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsPage;
