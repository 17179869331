import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { LogoIcon } from '../../icons/LogoIcon';
import UpTriangle from './UpTriangle';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../../scss/components/common/logo-button.scss';
import '../../scss/components/common/menu.scss';
import { LogoIconAlt } from '../../icons/LogoIconAlt';
import { SENDING_STATUS } from '../../utils/constants';
const LogoButton = () => {
  const history = useHistory();
  const { files, status } = useSelector((state) => state.transfer);
  const [show, setShow] = useState(false);
  const location = useLocation();

  const onClick = () => {
    if (location.pathname === '/new') {
      if (files.length > 0 && !(status == SENDING_STATUS.SENT || status == SENDING_STATUS.ERROR)) {
        setShow(!show);
        return;
      }

      history.push('/');
      return;
    }

    history.push('/');
  };

  const handleCancelButtonClicked = () => {
    setShow(!show);
  };

  return (
    <div className="logo-button" onClick={onClick}>
      {location.pathname === '/contact-us' || location.pathname === '/terms-conditions' ? (
        <LogoIconAlt />
      ) : (
        <LogoIcon width="189" height="46" color="#FFFFFF" />
      )}

      <UpTriangle show={show} />
      <Container
        fluid
        className="logo-container"
        style={{
          display: show ? 'flex' : 'none',
        }}
      >
        <div className="content">
          <Row className="text-container">
            <p>
              <strong>Return to Homepage? Your transfer will be discarded.</strong>
            </p>
            <p className="text">Your current session will be cancelled</p>
          </Row>
          <Row className="button-container">
            <Col>
              <Link to="/">
                <Button onClick={() => setShow(false)} className="btn-homepage" alt="HomePage">
                  HomePage
                </Button>
              </Link>
            </Col>
            <Col>
              <Button onClick={handleCancelButtonClicked} className="btn-cancel" alt="Cancel">
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default LogoButton;
