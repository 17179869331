import React from 'react';

export const ShowPasswordIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4C4 4 1 12 1 12C1 12 4 20 12 20C20 20 23 12 23 12C23 12 20 4 12 4ZM12 6C17.276 6 19.9446 10.2671 20.8086 11.9961C19.9436 13.7131 17.255 18 12 18C6.724 18 4.05541 13.7329 3.19141 12.0039C4.05741 10.2869 6.745 6 12 6ZM12 8C9.791 8 8 9.791 8 12C8 14.209 9.791 16 12 16C14.209 16 16 14.209 16 12C16 9.791 14.209 8 12 8ZM12 10C13.105 10 14 10.895 14 12C14 13.105 13.105 14 12 14C10.895 14 10 13.105 10 12C10 10.895 10.895 10 12 10Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
