import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../../scss/pages/signup.scss';
import { Col, Row } from 'react-bootstrap';
import IllustrationInfo from '../common/IllustrationInfo';
import FormColumn from './FormColumn';
import ErrorPopup from '../common/ErrorPopup';
import { haveAccount } from '../../utils';
import Background from '../common/Background';
import { gtagEvent } from '../../utils/analytics';
import useExitPrompt from '../common/useExitPrompt';

const SignUp = () => {
  const [errorPopupStatus, setErrorPopupStatus] = useState(false);
  const [errorText, setErrorText] = useState('Something went wrong');
  const [errorActionText, setErrorActionText] = useState('Try Again');
  const [errorAction, setErrorAction] = useState('');
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

  if (haveAccount()) {
    window.location.href = '/';
  }
  const history = useHistory();

  const action = () => {
    switch (errorAction) {
      case 'signin':
        window.location.href = '/signin';
        setErrorPopupStatus(false);
        break;
      default:
        setErrorPopupStatus(false);
        break;
    }
  };

  useEffect(() => {
    console.log({ showExitPrompt });
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  return (
    <div className="signup">
      <Background />
      <Row className="main-row">
        <Col className="illustration-col" md={6} xs={12}>
          <IllustrationInfo />
        </Col>
        <FormColumn
          verify={(email) => {
            setErrorPopupStatus(false);
            gtagEvent({
              action: 'sign_up_verification',
              category: 'Sign Up',
              label: 'From Sign Up',
              value: 1,
              method: 'from_sign_up',
            });
            history.push({ pathname: '../verify-account', state: { email, type: 'registered' } });
          }}
          errorCallback={(text, actionText, action) => {
            setErrorText(text);
            setErrorActionText(actionText);
            if (action) {
              setErrorAction(action);
            }
            setErrorPopupStatus(true);
          }}
          setShowExitPrompt={setShowExitPrompt}
        />
      </Row>
      {errorPopupStatus && <ErrorPopup text={errorText} actionText={errorActionText} action={action} />}
    </div>
  );
};

export default SignUp;
