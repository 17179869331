import React from 'react';

export const SocialTwitter = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.9287 11.8801C29.947 12.1446 29.947 12.409 29.947 12.676C29.947 20.8088 23.7556 30.1884 12.4345 30.1884V30.1836C9.09019 30.1884 5.81536 29.2305 3 27.4243C3.48629 27.4828 3.97502 27.512 4.46496 27.5132C7.23644 27.5157 9.9287 26.5858 12.1091 24.8734C9.47532 24.8234 7.16575 23.1062 6.35893 20.5992C7.28154 20.7771 8.23218 20.7405 9.13772 20.4931C6.2663 19.913 4.20049 17.3901 4.20049 14.4602C4.20049 14.4334 4.20049 14.4078 4.20049 14.3822C5.05606 14.8588 6.01402 15.1232 6.99391 15.1525C4.28946 13.3451 3.45582 9.74725 5.08897 6.93433C8.21389 10.7795 12.8245 13.1171 17.7739 13.3646C17.2779 11.2268 17.9555 8.98674 19.5545 7.48399C22.0335 5.15371 25.9324 5.27315 28.2626 7.7509C29.6411 7.47912 30.9622 6.97333 32.1712 6.25669C31.7118 7.68143 30.7502 8.89167 29.4656 9.66072C30.6856 9.5169 31.8775 9.19027 33 8.69179C32.1737 9.93006 31.1328 11.0087 29.9287 11.8801Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
