import React from 'react';

export const AttachIcon = () => {
  return (
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M78.8039 53.7165V16.5L41.2696 16.5L52.8186 28.2711L20.6146 60.4751C18.7638 62.9922 15.7963 69.8324 20.6146 75.5777C26.3887 82.4627 35.9392 78.687 38.3823 76.244C40.3367 74.2895 57.8527 56.3293 66.3664 47.5935C67.4769 46.335 68.5988 43.163 65.4781 40.0422C62.1466 36.7108 58.8892 38.1914 57.7047 38.9318L31.9415 64.2508"
        stroke="white"
        strokeWidth="6.0351"
        strokeLinecap="round"
      />
    </svg>
  );
};
