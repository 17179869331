import React from 'react';

const CalendarIcon = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M4 1.33325V2.66659H3.33333C2.6 2.66659 2 3.26659 2 3.99992V13.3333C2 14.0666 2.6 14.6666 3.33333 14.6666H12.6667C13.4 14.6666 14 14.0666 14 13.3333V3.99992C14 3.26659 13.4 2.66659 12.6667 2.66659H12V1.33325H10.6667V2.66659H5.33333V1.33325H4ZM3.33333 3.99992H4H5.33333H10.6667H12H12.6667V5.33325H3.33333V3.99992ZM3.33333 6.66659H12.6667V13.3333H3.33333V6.66659Z"
      fill={color || '#478FF3'}
    />
  </svg>
);

export default CalendarIcon;
