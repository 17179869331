import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProfileIcon } from '../../icons/ProfileIcon';
import '../../scss/components/common/menu.scss';
import '../../scss/components/common/sign-menu.scss';
import { useHasChanged } from '../../utils/component';
import UpTriangle from './UpTriangle';
import { isMobile } from '../../utils';
import { gtagEvent } from '../../utils/analytics';

const SignMenu = (props) => {
  let { visible: visibleCookie } = useSelector((state) => state.cookie);
  const { time } = props;
  const showStatus = !visibleCookie && !isMobile();
  const [show, setShow] = useState(showStatus);
  const hasCookieChanged = useHasChanged(visibleCookie);
  const location = useLocation();

  if (time) {
    setTimeout(() => {
      setShow(false);
    }, time * 1000);
  }

  useEffect(() => {
    if (hasCookieChanged) {
      setShow(!visibleCookie);
    }
  });

  const handleCloseButtonClicked = () => setShow(false);

  return (
    <div>
      <button
        style={{
          background: 'transparent',
          border: 0,
        }}
        onClick={() => setShow(true)} 
        aria-label="Sign In"
      >
        {/* eslint-disable */}
        {location.pathname === '/' ||
        location.pathname === '/terms-conditions' ||
        location.pathname === '/contact-us' ? (
          <ProfileIcon />
        ) : (
          <ProfileIcon color="#FFFFFF" />
        )}
        {/* eslint-enable */}
      </button>
      <div
        style={{
          display: show ? 'flex' : 'none',
        }}
      >
        <UpTriangle show={show} />
        <Container
          fluid
          className="menu sign-menu"
          style={{
            display: show ? 'flex' : 'none',
          }}
        >
          <Button className="btn-close" onClick={handleCloseButtonClicked} aria-label="Close"></Button>
          <div className="content">
            <Row className="text-container">
              <p>
                <strong>
                  Take advantage of a 5GB transfer limit by signing in and expanding your file sharing capabilities.
                </strong>
              </p>
            </Row>
            <Row className="button-container">
              <Col>
                <Link to="/signin">
                  <Button onClick={() => {
                    setShow(false);
                    gtagEvent({
                      action: 'sign_in_start',
                      category: 'Sign In',
                      label: 'From Header',
                      value: 1,
                      method: 'from_header',
                    });
                  }} className="btn-signin">
                    Sign In
                  </Button>
                </Link>
              </Col>
              <Col>
                <Link to="/signup">
                  <Button onClick={() => {
                    setShow(false);
                    gtagEvent({
                      action: 'sign_up_start',
                      category: 'Sign Up',
                      label: 'From Header',
                      value: 1,
                      method: 'from_header',
                    });
                  }} className="btn-signup">
                    Sign Up
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SignMenu;
