import React from 'react';
import { Field } from 'react-final-form';
import FormValidation from './FormValidation';

const FormCheckbox = ({ formName, onChange, value, validate }) => {
  return (
    <Field name={formName} type={'checkbox'} validate={validate}>
      {({ input, meta }) => (
        <div className="custom-form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              className="text"
              {...input}
              value={value}
              onChange={(e) => {
                input.onChange(e);

                if (onChange) {
                  onChange(e);
                }
              }}
            />
            <span className="slider round"></span>
          </label>
          <FormValidation meta={meta} />
        </div>
      )}
    </Field>
  );
};

export default FormCheckbox;
