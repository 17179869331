import React from 'react';

export const ValidationErrorIcon = ({ width, height, viewBox, color }) => {
  return (
    <svg
      width={width || '32'}
      height={height || '32'}
      viewBox={viewBox || '0 0 32 32'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2ZM13 17H11V15H13V17ZM13 13H11V7H13V13Z"
        fill={color || '#FF4848'}
      />
    </svg>
  );
};
