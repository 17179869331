export const setScrollY = (scrollY) => ({
  type: 'SCROLL',
  payload: {
    scrollY,
  },
});

export const setMaxScroll = (max) => ({
  type: 'MAX_SCROLL',
  payload: { max },
});

export const setLandingPageMinimized = (minimized) => ({
  type: 'LANDING_MINIMIZED',
  payload: { minimized },
});
