import React from 'react';
import SignMenu from './SignMenu';

const ProfileButton = (props) => {
  const { showTime } = props;

  return (
    <div className="menu-button">
      <SignMenu time={showTime} />
    </div>
  );
};

export default ProfileButton;
