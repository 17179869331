import React from 'react';

export const DocIcon = ({ width, height }) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width || '48'} height={height || '48'} fill="#FFFFFF" />
      <path
        d="M13 4C10.8115 4 9 5.81149 9 8V18C6.79 18 5 19.79 5 22V32C5 34.21 6.79 36 9 36V40C9 42.1885 10.8115 44 13 44H37C39.1885 44 41 42.1885 41 40V32V22V14L31 4H13ZM13 8H29V16H37V18H13V8ZM33 22C35.21 22 37 23.79 37 26H35C35 24.898 34.102 24 33 24C31.898 24 31 24.898 31 26V28C31 29.102 31.898 30 33 30C34.102 30 35 29.102 35 28H37C37 30.21 35.21 32 33 32C30.79 32 29 30.21 29 28V26C29 23.79 30.79 22 33 22ZM9 22.0469H12.2578C15.4238 22.0469 17 23.9498 17 26.2578V27.8008C17 30.1368 15.4185 32 12.2305 32H9V22.0469ZM23 22.1289C26.728 22.0689 27 25.6848 27 26.3828V28C27 28.684 26.8276 32.2031 23.0156 32.1211C18.9336 32.0371 19 28.684 19 28V26.3828C19 25.6808 19.334 22.1869 23 22.1289ZM23.0039 23.7734C21.1139 23.8114 21 25.9071 21 26.3711V28C21 28.444 21.3094 30.5184 23.0234 30.4844C24.6674 30.4544 25 28.442 25 28V26.3711C25 25.9071 24.7759 23.7374 23.0039 23.7734ZM11 24.0469V30H12.2305C13.4945 30 15 29.6168 15 27.8008V26.2578C15 24.4298 13.5098 24.0469 12.2578 24.0469H11ZM13 36H37V40H13V36Z"
        fill="#8892A4"
      />
    </svg>
  );
};
