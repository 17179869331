import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import FilePreview from '../transfer/FilePreview';
import DownloadIcon from '../../icons/DownloadIcon';
import { formatBytes } from '../../utils';
import mime from 'react-native-mime-types';
import { ToastContainer, toast } from 'react-toastify';
import api from '../../api';

const DownloadColumn = ({ transfer }) => {
  const [downloadClicked, toggleDownloadClicked] = useState(false);

  const downloadTransfer = async (transferId) => {
    if (downloadClicked) {
      return;
    }

    try {
      await api.patch(`/transfers/${transferId}/download`);
      toggleDownloadClicked(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Col className="download-col" md={4}>
      <ToastContainer />
      <div className="sender">
        Files sent by: <span>{transfer.senderEmail}</span>
      </div>

      <div className="files">
        {transfer.files.map((file, index) => {
          file.type = mime.lookup(file.name);

          return (
            <div className="file d-flex align-items-center" key={index}>
              <div className="preview">
                <FilePreview file={file} width="20" height="20" imagePreview={false} />
              </div>
              <div className="detail">
                <span className="name">{file.name}</span>
                <span className="size">{formatBytes(file.size)}</span>
              </div>
              <div
                className="download-button d-flex align-items-center"
                onClick={() => {
                  downloadTransfer(transfer.guid);
                  const link = document.createElement('a');
                  link.href = file.link;
                  link.download = 'filename'; // optional, you can give a specific filename
                  link.style.display = 'none';
                  link.target = '_blank';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                <DownloadIcon />
              </div>
            </div>
          );
        })}
      </div>

      <Button
        className="button"
        onClick={() => {
          downloadTransfer(transfer.guid);
          if (transfer.link) {
            window.open(transfer.link, '_blank');
            return;
          }

          toast.success("Zip file's still in the oven! Check back soon!", {
            position: toast.POSITION.TOP_CENTER,
            className: 'toast-message',
          });
        }}
      >
        Download All
      </Button>
    </Col>
  );
};

export default DownloadColumn;
