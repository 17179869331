import React from 'react';

export const PptIcon = ({ width, height }) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width || '48'} height={height || '48'} fill="#FFFFFF" />
      <path
        d="M13 4C10.8115 4 9 5.81149 9 8V18C6.79 18 5 19.79 5 22V32C5 34.21 6.79 36 9 36V40C9 42.1885 10.8115 44 13 44H37C39.1885 44 41 42.1885 41 40V14L31 4H13ZM13 8H29V16H37V40H13V36H31C33.21 36 35 34.21 35 32V22C35 19.79 33.21 18 31 18H13V8ZM9 22H12C13.656 22 15 23.344 15 25C15 26.656 13.656 28 12 28H11V32H9V22ZM17 22H20C21.656 22 23 23.344 23 25C23 26.656 21.656 28 20 28H19V32H17V22ZM25 22H31V24H29V32H27V24H25V22ZM11 24V26H12C12.552 26 13 25.552 13 25C13 24.448 12.552 24 12 24H11ZM19 24V26H20C20.552 26 21 25.552 21 25C21 24.448 20.552 24 20 24H19Z"
        fill="#8892A4"
      />
    </svg>
  );
};
