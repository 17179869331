import React, { useState } from 'react';
import '../../scss/pages/signin.scss';
import { Col, Row } from 'react-bootstrap';
import IllustrationInfo from '../common/IllustrationInfo';
import FormColumn from './FormColumn';
import ErrorPopup from '../common/ErrorPopup';
import { haveAccount } from '../../utils';
import Background from '../common/Background';

const SignIn = () => {
  const [errorPopupStatus, setErrorPopupStatus] = useState(false);
  if (haveAccount()) {
    window.location.href = '/';
  }

  return (
    <div className="signin">
      <Background />
      <Row className="main-row">
        <Col className="illustration-col" md={6} xs={12}>
          <IllustrationInfo />
        </Col>
        <FormColumn
          errorCallback={() => {
            setErrorPopupStatus(true);
          }}
        />
      </Row>
      {errorPopupStatus && (
        <ErrorPopup text="Something went wrong" actionText="Try Again" action={() => setErrorPopupStatus(false)} />
      )}
    </div>
  );
};

export default SignIn;
