import React, { useState } from 'react';
import api from '../../api';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AttachIcon } from '../../icons/AttachIcon';
import { Col, Row, Button } from 'react-bootstrap';
import { Form } from 'react-final-form';
import FormTextField from '../common/form/FormTextField';
import { setToken, setAccount } from '../../store/account/actions';
import { composeValidators, required, validEmail } from '../../utils/form';
import { Link } from 'react-router-dom';
import { PasswordIcon } from '../../icons/PasswordIcon';

import { ToastContainer, toast } from 'react-toastify';
import { gtagEvent } from '../../utils/analytics';

const FormColumn = ({ errorCallback }) => {
  const history = useHistory();
  let { transactionId } = useSelector((state) => state.app);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPasswordModal, toggleForgotPasswordModal] = useState(false);

  const onSubmit = async () => {
    if (forgotPasswordModal) {
      return forgotPassword();
    }
    const error = await dispatch(auth());

    if (error === 'wrong_credentials') {
      return { password: 'Wrong email or password' };
    } else if (error === 'account_not_verified') {
      history.push({ pathname: '/verify-account', state: { email } });
      gtagEvent({
        action: 'sign_up_verification',
        category: 'Sign Up',
        label: 'From Sign In',
        value: 1,
        method: 'from_sign_in',
      });
      return;
    } else if (error) {
      errorCallback();
      return;
    }

    gtagEvent({
      action: 'sign_in',
      category: 'Sign In',
      label: 'Sign In',
      value: 1,
    });

    history.push('/');
  };

  const dispatch = useDispatch();

  const auth = () => async (dispatch) => {
    try {
      const response = await api.post('/auth/login', {
        transactionId,
        email,
        password,
      });
      const { data } = response.data;
      localStorage.setItem('token', data.token);
      dispatch(setToken(data.token));
      localStorage.setItem('account', JSON.stringify(data.account));
      dispatch(setAccount(data.account));
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        return error.response.data.error.code;
      }

      return error;
    }
  };

  const forgotPassword = async () => {
    try {
      await api.post('/auth/password/forget', {
        email,
      });

      toast.success('Password reset mail is sent!', {
        position: toast.POSITION.BOTTOM_CENTER,
        className: 'toast-message',
      });

      gtagEvent({
        action: 'forget_password',
        category: 'Sign In',
        label: 'Forget Password',
        value: 1,
        method: 'forget_password',
      });

      setEmail('');
    } catch (error) {
      errorCallback();
      return;
    }
  };

  return (
    <Col className="signin-col" md={6} xs={12}>
      <ToastContainer />
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitting }) => (
          <form>
            {forgotPasswordModal ? (
              <div>
                <PasswordIcon />
                <h4 className="header">Forgot Password</h4>
                <Col>
                  <FormTextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    formName="email"
                    spanText="E-Mail"
                    validate={composeValidators(required, validEmail)}
                  />
                </Col>
                <Row className="sign-buttons">
                  <Col>
                    <Button
                      className="signup-button"
                      onClick={() => {
                        toggleForgotPasswordModal(!forgotPasswordModal);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                  <Col>
                    <Button className="signin-button" onClick={handleSubmit} disabled={submitting}>
                      Reset My Password
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <AttachIcon />
                <h2 className="header">Sign In with Your SafeTP account</h2>
                <Col>
                  <FormTextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    formName="email"
                    spanText="E-Mail"
                    validate={composeValidators(required, validEmail)}
                  />
                </Col>
                <Col>
                  <FormTextField
                    spanText="Password"
                    formName="password"
                    validate={required}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    hyperlinkButton={{
                      text: 'Forgot your password?',
                      onClick: () => {
                        toggleForgotPasswordModal(!forgotPasswordModal);
                      },
                    }}
                  />
                </Col>
                <Row className="sign-buttons">
                  <Col xs={12} md={6}>
                    <Link to="/signup" onClick={()=> {
                      gtagEvent({
                        action: 'sign_up_start',
                        category: 'Sign Up',
                        label: 'From Sign In',
                        value: 1,
                        method: 'from_sign_in',
                      });
                    }}>
                      <Button className="signup-button" disabled={submitting}>
                        Sign Up
                      </Button>
                    </Link>
                  </Col>

                  <Col xs={12} md={6}>
                    <button className="signin-button" onClick={handleSubmit} disabled={submitting}>
                      Sign In
                    </button>
                  </Col>
                </Row>

                {/* <Row>
                  <Col>
                    <div className="signup-button option">
                      <p>Sign In with Apple</p>
                      <img src={appleIcon} />
                    </div>
                  </Col>
                  <Col>
                    <div className="signup-button option">
                      <p>Sign In with Google</p>
                      <img src={googleIcon} />
                    </div>
                  </Col>
                </Row> */}
              </div>
            )}
          </form>
        )}
      />
    </Col>
  );
};

export default FormColumn;
