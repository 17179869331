import React from 'react';

export const VideoIcon = ({ width, height }) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width || '48'} height={height || '48'} fill="#FFFFFF" />
      <path
        d="M8 6C5.81387 6 4 7.81387 4 10V38C4 40.1861 5.81387 42 8 42H40C42.1861 42 44 40.1861 44 38V10C44 7.81387 42.1861 6 40 6H8ZM8 10H40V38H8V10ZM18 16V32L32 24L18 16Z"
        fill="#8892A4"
      />
    </svg>
  );
};
