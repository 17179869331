import React, { useState } from 'react';
import blackbig from '../../assets/images/background-black-big.png';
import blacksmall from '../../assets/images/background-black-small.png';
import bluebig from '../../assets/images/background-blue-big.png';
import bluesmall from '../../assets/images/background-blue-small.png';
import graybig from '../../assets/images/background-gray-big.png';
import graysmall from '../../assets/images/background-gray-small.png';
import orangebig from '../../assets/images/background-orange-big.png';
import orangesmall from '../../assets/images/background-orange-small.png';
import pinkbig from '../../assets/images/background-pink-big.png';
import pinksmall from '../../assets/images/background-pink-small.png';
import '../../scss/components/common/view-dot.scss';
import ColorPicker from '../common/ColorPicker';
import ViewDot from '../common/ViewDot';

export const COLORS = {
  BLUE: {
    name: 'blue',
    hex: '#478FF3',
  },
  ORANGE: {
    name: 'orange',
    hex: '#FFAB48',
  },
  PINK: {
    name: 'pink',
    hex: '#A938D0',
  },
  BLACK: {
    name: 'black',
    hex: '#192E41',
  },
  GRAY: {
    name: 'gray',
    hex: '#8892A4',
  },
};

export const findColorByName = (name) => {
  const keys = Object.keys(COLORS);
  const i = Object.values(COLORS).findIndex((color) => color.name === name);
  if (i === -1) {
    return null;
  }

  return COLORS[keys[i]];
};

const VIEWS = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

const ChangeBackground = ({ passwordPageShown, bgColor, setBgColor }) => {
  const [view, setView] = useState(VIEWS.DESKTOP);

  let viewer;
  if (view === VIEWS.DESKTOP) {
    viewer = <BackgroundViewer color={bgColor?.name} />;
  } else {
    viewer = <SmallerBackgroundViewer color={bgColor?.name} />;
  }

  return (
    <div className={`change-background ${passwordPageShown ? 'blurred' : ''}`}>
      <h2>Profile</h2>
      <p>Pick a background color for download page:</p>
      <div className="picker-container">
        <div className="image-container">{viewer}</div>
        <div className="view-selector-container">
          <ViewDot active={view === VIEWS.DESKTOP} onClick={() => setView(VIEWS.DESKTOP)} />
          <ViewDot active={view === VIEWS.MOBILE} onClick={() => setView(VIEWS.MOBILE)} />
        </div>
        <ColorPicker colors={COLORS} bgColor={bgColor?.name} handleColorChange={(color) => setBgColor(color)} />
      </div>
    </div>
  );
};

const BackgroundViewer = ({ color }) => {
  let src;
  switch (color) {
    case COLORS.BLUE.name:
      src = bluebig;
      break;
    case COLORS.ORANGE.name:
      src = orangebig;
      break;
    case COLORS.PINK.name:
      src = pinkbig;
      break;
    case COLORS.BLACK.name:
      src = blackbig;
      break;
    case COLORS.GRAY.name:
      src = graybig;
      break;
  }

  return <img src={src} />;
};

const SmallerBackgroundViewer = ({ color }) => {
  let src;
  switch (color) {
    case COLORS.BLUE.name:
      src = bluesmall;
      break;
    case COLORS.ORANGE.name:
      src = orangesmall;
      break;
    case COLORS.PINK.name:
      src = pinksmall;
      break;
    case COLORS.BLACK.name:
      src = blacksmall;
      break;
    case COLORS.GRAY.name:
      src = graysmall;
      break;
  }

  return <img src={src} />;
};

export default ChangeBackground;
