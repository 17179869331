import React from 'react';

export const SocialLinkedin = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.5 6.48529V29.5147C31.5 30.0412 31.2908 30.5462 30.9185 30.9185C30.5462 31.2908 30.0412 31.5 29.5147 31.5H6.48529C5.95876 31.5 5.45379 31.2908 5.08148 30.9185C4.70916 30.5462 4.5 30.0412 4.5 29.5147V6.48529C4.5 5.95876 4.70916 5.45379 5.08148 5.08148C5.45379 4.70916 5.95876 4.5 6.48529 4.5H29.5147C30.0412 4.5 30.5462 4.70916 30.9185 5.08148C31.2908 5.45379 31.5 5.95876 31.5 6.48529ZM12.4412 14.8235H8.47059V27.5294H12.4412V14.8235ZM12.7985 10.4559C12.8006 10.1555 12.7435 9.85773 12.6305 9.57945C12.5175 9.30117 12.3508 9.04787 12.1399 8.83402C11.929 8.62017 11.6781 8.44996 11.4014 8.3331C11.1247 8.21624 10.8277 8.15502 10.5274 8.15294H10.4559C9.8451 8.15294 9.25934 8.39557 8.82746 8.82746C8.39557 9.25934 8.15294 9.8451 8.15294 10.4559C8.15294 11.0667 8.39557 11.6524 8.82746 12.0843C9.25934 12.5162 9.8451 12.7588 10.4559 12.7588C10.7562 12.7662 11.0551 12.7143 11.3354 12.6062C11.6157 12.498 11.872 12.3357 12.0896 12.1285C12.3071 11.9212 12.4817 11.6732 12.6033 11.3984C12.725 11.1237 12.7913 10.8277 12.7985 10.5274V10.4559ZM27.5294 19.8106C27.5294 15.9909 25.0994 14.5059 22.6853 14.5059C21.8949 14.4663 21.1079 14.6347 20.4028 14.9942C19.6977 15.3537 19.0993 15.8917 18.6671 16.5547H18.5559V14.8235H14.8235V27.5294H18.7941V20.7715C18.7367 20.0793 18.9547 19.3925 19.4008 18.8602C19.8469 18.3279 20.485 17.9931 21.1765 17.9285H21.3274C22.59 17.9285 23.5271 18.7226 23.5271 20.7238V27.5294H27.4976L27.5294 19.8106Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
