import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { PasswordIcon } from '../../icons/PasswordIcon';
import api from '../../api';
import {
  composeValidators,
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCase,
  lengthGreaterThan,
  required,
  samePassword,
  validPassword,
} from '../../utils/form';
import FormTextField from '../common/form/FormTextField';
import Requirement from '../common/Requirement';
import { ToastContainer, toast } from 'react-toastify';

const ChangePassword = ({ handleBack }) => {
  const [passwords, setPasswords] = useState({
    current: '',
    password: null,
    verify: null,
  });

  const updatePassword = async (form) => {
    try {
      await api.put(`/account/password`, {
        oldPassword: form['current-password'],
        newPassword: form.password,
      });

      toast.success('Password successfully updated', {
        position: toast.POSITION.TOP_CENTER,
        className: 'toast-message',
      });

      setPasswords({ current: '', password: '', verify: null });
    } catch (error) {
      if (error?.response?.data?.error?.code === 'invalid_password') {
        return { 'current-password': 'Wrong password' };
      } else if (error?.response?.data?.error?.code === 'new_password_same_as_old') {
        return { password: 'New password cannot be the same as the old password' };
      } else {
        return { password: 'Invalid password' };
      }
    }
  };
  return (
    <div className="change-password-container">
      <ToastContainer />
      <Row className="header">
        <Col>
          <PasswordIcon />
          <h4>Password Change</h4>
        </Col>
      </Row>

      <Form
        onSubmit={updatePassword}
        initialValues={{
          'current-password': passwords.current,
          password: passwords.password,
          'new-password-verify': passwords.verify,
        }}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit }) => (
          <form>
            <Row>
              <Col md={6}>
                <FormTextField
                  formName="current-password"
                  spanText="Current Password"
                  type={'password'}
                  onChange={(e) => setPasswords({ ...passwords, current: e.target.value })}
                  validate={composeValidators(required)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormTextField
                  formName="password"
                  type={'password'}
                  spanText="New Password"
                  onChange={(e) => setPasswords({ ...passwords, password: e.target.value })}
                  validate={composeValidators(required, validPassword)}
                />
              </Col>
              <Col md={6}>
                <FormTextField
                  formName="new-password-verify"
                  type={'password'}
                  spanText="Verify New Password"
                  onChange={(e) => setPasswords({ ...passwords, verify: e.target.value })}
                  validate={passwords.password != '' ? samePassword : required}
                />
              </Col>
            </Row>
            <Row className="password-requirements-container">
              <Row>
                <Col md={6}>
                  <Requirement text={'One lowercase character'} rule={() => hasLowerCase(passwords.password)} />
                </Col>
                <Col md={6}>
                  <Requirement text={'One special character'} rule={() => hasSpecialCharacter(passwords.password)} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Requirement text={'One uppercase character'} rule={() => hasUpperCase(passwords.password)} />
                </Col>
                <Col md={6}>
                  <Requirement text={'8 characters minimum'} rule={() => lengthGreaterThan(passwords.password, 8)} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Requirement text={'One number'} rule={() => hasNumber(passwords.password)} />
                </Col>
              </Row>
            </Row>
            <Row className="button-container">
              <Col md={12}>
                <Col md={5}>
                  <Button type="button" className="btn-back" onClick={handleBack}>
                    <p>Back</p>
                  </Button>
                </Col>
                <Col md={5}>
                  <Button type="button" onClick={handleSubmit} className="btn-save-password">
                    <p>Change Password</p>
                  </Button>
                </Col>
              </Col>
            </Row>
          </form>
        )}
      />
    </div>
  );
};

export default ChangePassword;
