import React from 'react';

export const MyGroupIcon = ({ width, height, viewBox, color }) => {
  return (
    <svg
      width={width || '20'}
      height={height || '16'}
      viewBox={viewBox || '0 0 100 80'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.475 0 0 4.475 0 10V70C0 75.525 4.475 80 10 80H90C95.525 80 100 75.525 100 70V10C100 4.475 95.525 0 90 0H10ZM10 10H90V15.0098L50 40L10 15.0098V10ZM10 25.0098L50 50L90 25.0098V70H10V25.0098Z"
        fill={color || '#2C2C2C'}
      />
    </svg>
  );
};
