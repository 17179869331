import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideCookiePopup } from '../../store/cookie/actions';
import '../../scss/components/common/cookie-policy.scss';

const CookiePolicy = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { visible } = useSelector((state) => state.cookie);
  const dispatch = useDispatch();

  return (
    visible && (
      <div className="cookie-container">
        <p 
          className="color-secondary cookie-text"
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ cursor: 'pointer' }}
        >

          {isExpanded ? 
            `We use cookies and similar technologies to provide a better experience. By clicking accept, 
            you agree to this, as outlined in our Cookie Policy. By accepting, you acknowledge our use of 
            cookies. See our Cookie Policy under the Terms and Conditions for details.` : 
            'We use cookies to enhance your browsing experience. Click here to learn mo...'
          }
          <span className="arrow">{isExpanded ? '▲' : '▼'}</span>
        </p>
        <button
          alt="Accept cookies"
          className="cookie-button"
          onClick={() => {
            localStorage.setItem('hideBanner', true);
            dispatch(hideCookiePopup());
          }}
        >
          It&#39;s Okay
        </button>
      </div>
    )
  );
};

export default CookiePolicy;