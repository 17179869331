import React from 'react';

export const SocialFacebook = () => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33 18.091C33 9.756 26.285 3 18 3C9.715 3 3 9.756 3 18.091C3 25.625 8.484 31.868 15.656 33V22.454H11.848V18.09H15.656V14.766C15.656 10.984 17.895 8.894 21.322 8.894C22.962 8.894 24.68 9.189 24.68 9.189V12.903H22.787C20.924 12.903 20.344 14.067 20.344 15.261V18.091H24.504L23.839 22.453H20.344V33C27.516 31.868 33 25.625 33 18.091Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};
