import React from 'react';

export const CopyLinkIcon = () => {
  return (
    <svg width="22" height="10" viewBox="0 0 22 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 0C2.239 0 0 2.239 0 5C0 7.761 2.239 10 5 10H9V8H5C3.343 8 2 6.657 2 5C2 3.343 3.343 2 5 2H9V0H5ZM13 0V2H17C18.657 2 20 3.343 20 5C20 6.657 18.657 8 17 8H13V10H17C19.761 10 22 7.761 22 5C22 2.239 19.761 0 17 0H13ZM6 4V6H16V4H6Z"
        fill="#8892A4"
      />
    </svg>
  );
};
