import React from 'react';

const TimeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
    <circle cx="11" cy="10.125" r="8.9903" stroke="#478FF3" strokeWidth="2.2694" />
    <rect x="9.90918" y="4.49512" width="2.22575" height="6.10991" fill="#478FF3" />
    <rect
      x="8.42773"
      y="14.2734"
      width="2.22575"
      height="5.21363"
      transform="rotate(-135 8.42773 14.2734)"
      fill="#478FF3"
    />
  </svg>
);

export default TimeIcon;
