import React from 'react';
import '../../scss/pages/resetpassword.scss';
import { Col, Row } from 'react-bootstrap';
import IllustrationInfo from '../common/IllustrationInfo';
import FormColumn from './FormColumn';
import { haveAccount } from '../../utils';
import Background from '../common/Background';

const ForgotPassword = ({ location }) => {
  if (haveAccount()) {
    window.location.href = '/';
  }

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  return (
    <div className="resetpassword">
      <Background />
      <Row className="main-row">
        <Col className="illustration-col" md={6} xs={12}>
          <IllustrationInfo />
        </Col>
        <FormColumn token={token} />
      </Row>
    </div>
  );
};

export default ForgotPassword;
