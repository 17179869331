import React from 'react';

const ProgressLine = ({ color = '#8892A4' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 64 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="-2.62268e-07" y1="3" x2="64" y2="2.99999" stroke={color} strokeWidth="6" strokeDasharray="12 12" />
    </svg>
  );
};

export default ProgressLine;
