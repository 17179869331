import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import downloadinfoonepng from '../../assets/images/encryption.png';
import '../../scss/components/common/view-dot.scss';
import '../../scss/pages/download.scss';
import ViewDot from '../common/ViewDot';
const INFOS = {
  ONE: '1',
  TWO: '2',
  THREE: '3',
};

const InfoColumn = () => {
  const [activeInfo, setActiveInfo] = useState(INFOS.ONE);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeInfo === INFOS.ONE) {
        setActiveInfo(INFOS.TWO);
      } else if (activeInfo === INFOS.TWO) {
        setActiveInfo(INFOS.THREE);
      } else {
        setActiveInfo(INFOS.ONE);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [activeInfo]);

  return (
    <Col className="introduction-col" md={8}>
      <Info
        active={activeInfo === INFOS.ONE}
        title={'Access Your Files'}
        descText={"Access your files securely from anywhere with SafeTP's easy-to-use platform. Your data, your way."}
        src={downloadinfoonepng}
      />
      <Info
        active={activeInfo === INFOS.TWO}
        title={'Access Your Files'}
        descText={"Take control of your files and access them hassle-free through SafeTP's encrypted file access."}
        src={downloadinfoonepng}
      />
      <Info
        active={activeInfo === INFOS.THREE}
        title={'Access Your Files'}
        descText={'Instantly view and manage your files, knowing they are protected during transfers with SafeTP.'}
        src={downloadinfoonepng}
      />

      <div className="view-selector-container">
        <ViewDot active={activeInfo === INFOS.ONE} onClick={() => setActiveInfo(INFOS.ONE)} />
        <ViewDot active={activeInfo === INFOS.TWO} onClick={() => setActiveInfo(INFOS.TWO)} />
        <ViewDot active={activeInfo === INFOS.THREE} onClick={() => setActiveInfo(INFOS.THREE)} />
      </div>
    </Col>
  );
};

const Info = ({ active, src, title, descText }) => {
  return (
    <div
      className="illustration"
      style={{
        display: active ? 'flex' : 'none',
      }}
    >
      <img src={src} />
      <p className="illustration-header">{title}</p>
      <p className="illustration-text">{descText}</p>
    </div>
  );
};

export default InfoColumn;
