import React from 'react';

const ReceiverEmails = ({ receiverEmails, receiverEmailColors }) => {
  return (
    receiverEmails.length > 0 && (
      <div className="receiver-emails">
        {receiverEmails.map((receiver, index) => {
          if (index > 4) {
            return;
          }
          return (
            <div
              className="email"
              style={{
                border: `2px solid #${receiverEmailColors[index]}`,
                zIndex: 5 - index,
                left: index * 13,
              }}
              key={receiver}
            >
              <span style={{ color: '#' + receiverEmailColors[index] }}>{receiver[0].toUpperCase()}</span>
            </div>
          );
        })}
        <span className="other-emails-count">{receiverEmails.length > 5 && ` +${receiverEmails.length - 5}`}</span>
      </div>
    )
  );
};

export default ReceiverEmails;
