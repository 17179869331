import React from 'react';
import encryption from '../../assets/images/encryption.png';
import '../../scss/components/common/illustration.scss';

const IllustrationInfo = ({ header, text }) => {
  return (
    <div className="illustration">
      <img src={encryption} alt='Encrypt your files'/>
      <p className="illustration-header">{header || 'Safe & Reliable File Transfer!'}</p>
      <p className="illustration-text">
        {text ||
          `Keep your files protected with SafeTP's advanced security technology.
        Sign in now to experience worry-free file transfers.`}
      </p>
    </div>
  );
};

export default IllustrationInfo;
