import React from 'react';

export const AddPasswordIcon = ({ color = '#478FF3' }) => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0.333344C3.78413 0.333344 2.00001 2.03251 2.00001 4.14287V4.77779C1.2713 4.77779 0.666672 5.35362 0.666672 6.04763V12.3968C0.666672 13.0908 1.2713 13.6667 2.00001 13.6667H10C10.7287 13.6667 11.3333 13.0908 11.3333 12.3968V6.04763C11.3333 5.35362 10.7287 4.77779 10 4.77779V4.14287C10 2.03251 8.21588 0.333344 6 0.333344ZM6 1.60318C7.51746 1.60318 8.66667 2.69767 8.66667 4.14287V4.77779H3.33334V4.14287C3.33334 2.69767 4.48254 1.60318 6 1.60318ZM2.00001 6.04763H10V12.3968H2.00001V6.04763ZM6 7.95239C5.26667 7.95239 4.66667 8.52382 4.66667 9.22223C4.66667 9.92065 5.26667 10.4921 6 10.4921C6.73334 10.4921 7.33334 9.92065 7.33334 9.22223C7.33334 8.52382 6.73334 7.95239 6 7.95239Z"
        fill={color}
      />
    </svg>
  );
};
