import React from 'react';
import { Field } from 'react-final-form';

const FormTextField = ({ formName, onChange, value }) => {
  return (
    <Field name={formName} type={'checkbox'}>
      {({ input }) => (
        <label className="switch">
          <input
            type="checkbox"
            className="text"
            {...input}
            value={value}
            onChange={(e) => {
              input.onChange(e);

              if (onChange) {
                onChange(e);
              }
            }}
          />
          <span className="slider round"></span>
        </label>
      )}
    </Field>
  );
};

export default FormTextField;
