import React from 'react';
import '../../scss/components/common/error-popup.scss';

const ErrorPopup = ({ text, actionText, action }) => {
  return (
    <div className="error-popup">
      <p className="color-secondary error-text">{text}</p>
      <button className="error-button" onClick={action}>
        {actionText}
      </button>
    </div>
  );
};

export default ErrorPopup;
