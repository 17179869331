import React from 'react';
import transferDoneIllustration from '../../../assets/illustrations/transfer_done.png';
import { Button, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';

function TransferDone() {
  const transfer = useSelector((state) => state.transfer);
  const expireDays = transfer.form.expire.duration;
  const history = useHistory();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(transfer.link);
      toast.success('Link is copied to clipboard!', {
        position: toast.POSITION.TOP_CENTER,
        className: 'toast-message',
      });
    } catch (err) {
      console.error('Error', err);
    }
  };

  return (
    <div className="transfer-done">
      <ToastContainer />
      <img src={transferDoneIllustration} />
      <h1>All Done, well done!</h1>
      <p>
        We will keep your files for <span>{`${expireDays} ${expireDays > 1 ? 'days' : 'day'}`}</span> as you asked.
      </p>
      <Row className="buttons">
        <Col md={6} xs={12}>
          <Button className="button get-link-button" onClick={handleCopy}>
            Get Link
          </Button>
        </Col>
        <Col md={6} xs={12}>
          <Button
            className="button homepage-button"
            onClick={() => {
              history.push('/');
            }}
          >
            Homepage
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default TransferDone;
