import React from 'react';

const FileUploadingIcon = ({ color = '#8892A4' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.25 12C26.5597 12 21.7702 15.0456 18.6724 19.3458C10.0333 19.4439 3 26.4948 3 35.1562C3 43.8783 10.1217 51 18.8438 51H48.0938C55.4686 51 61.5 44.9686 61.5 37.5938C61.5 30.3047 55.594 24.3732 48.3365 24.2351C46.2167 17.2201 39.9332 12 32.25 12ZM32.25 16.875C38.3548 16.875 43.3503 21.3246 44.2709 27.1487L44.6422 29.491L46.994 29.1863C47.5384 29.1154 47.8863 29.0625 48.0938 29.0625C52.8333 29.0625 56.625 32.8542 56.625 37.5938C56.625 42.3333 52.8333 46.125 48.0938 46.125H34.6875V36.375H42L32.25 26.625L22.5 36.375H29.8125V46.125H18.8438C12.7555 46.125 7.875 41.2445 7.875 35.1562C7.875 29.068 12.7555 24.1875 18.8438 24.1875C18.929 24.1875 19.1276 24.2045 19.4531 24.2208L20.9432 24.297L21.6859 23.0021C23.7921 19.3426 27.7111 16.875 32.25 16.875Z"
        fill={color}
      />
    </svg>
  );
};

export default FileUploadingIcon;
