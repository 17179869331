import React from 'react';

export const PdfIcon = ({ width, height }) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width || '48'} height={height || '48'} fill="#FFFFFF" />
      <path
        d="M13 4C10.8115 4 9 5.81149 9 8V18C6.79 18 5 19.79 5 22V32C5 34.21 6.79 36 9 36V40C9 42.1885 10.8115 44 13 44H37C39.1885 44 41 42.1885 41 40V14L31 4H13ZM13 8H29V16H37V18H13V8ZM9 22H12C13.656 22 15 23.344 15 25C15 26.656 13.656 28 12 28H11V32H9V22ZM27 22H33V24H29V26H32.1992V28H29V32H27V22ZM17 22.0469H20.2578C23.4218 22.0469 25 23.9498 25 26.2578V27.8008C25 30.1368 23.4185 32 20.2305 32H17V22.0469ZM11 24V26H12C12.552 26 13 25.552 13 25C13 24.448 12.552 24 12 24H11ZM19 24.0469V30H20.2305C21.4945 30 23 29.6168 23 27.8008V26.2578C23 24.4298 21.5098 24.0469 20.2578 24.0469H19ZM13 36H37V40H13V36Z"
        fill="#8892A4"
      />
    </svg>
  );
};
