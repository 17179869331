import React from 'react';

export const LargePlusIcon = ({ width, height, viewBox }) => {
  return (
    <svg
      width={width || '96'}
      height={height || '96'}
      viewBox={viewBox || '0 0 96 96'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48 8C25.956 8 8 25.956 8 48C8 70.044 25.956 88 48 88C70.044 88 88 70.044 88 48C88 25.956 70.044 8 48 8ZM48 16C65.7205 16 80 30.2795 80 48C80 65.7205 65.7205 80 48 80C30.2795 80 16 65.7205 16 48C16 30.2795 30.2795 16 48 16ZM44 28V44H28V52H44V68H52V52H68V44H52V28H44Z"
        fill="#478FF3"
      />
    </svg>
  );
};
