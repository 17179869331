import React, { useState } from 'react';
import api from '../../api';
import { Button, Col, Row } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import FormTextField from '../common/form/FormTextField';
import {
  composeValidators,
  required,
  validEmail,
  samePassword,
  validPassword,
  termsAndConditions,
} from '../../utils/form';
import FormCheckbox from '../common/form/FormCheckbox';
import { Link } from 'react-router-dom';
import { gtagEvent } from '../../utils/analytics';

const FormColumn = ({ errorCallback, verify, setShowExitPrompt }) => {
  const dispatch = useDispatch();
  let { transactionId } = useSelector((state) => state.app);
  // const [signInSelect, setSignInSelect] = React.useState(false);
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [optin, setOptin] = useState(false);
  const [email, setEmail] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    surname: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const regex = /^[\p{L} ]*$/u;
    if (regex.test(value)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
      setShowExitPrompt(true);
    }
  };

  const onSubmit = async () => {
    const error = await dispatch(signup());

    if (error) {
      if (error === 'validation_failed') {
        errorCallback('Some fields are not correct, please check the fields.', 'Try Again');
        return;
      } else if (error === 'account_already_registered') {
        errorCallback('Account has already registered', 'Sign In', 'signin');
        return;
      }

      errorCallback('Something went wrong', 'Try again');
      return;
    }

    gtagEvent({
      action: 'sign_up_form_sent',
      category: 'Sign Up',
      label: 'Sign Up Form Sent',
      value: 1,
    });

    verify(email);
  };

  const signup = () => async () => {
    try {
      await api.post('/account/', {
        transactionId,
        password,
        firstName: formValues.name,
        lastName: formValues.surname,
        email,
        optin: optin,
        termsAccepted: terms,
      });
    } catch (error) {
      if (error?.response?.data?.error?.code) {
        return error.response.data.error.code;
      }

      return error;
    }
  };

  return (
    <Col className="signup-col" md={6} xs={12}>
      <Form
        onSubmit={onSubmit}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitting }) => (
          <form>
            <h2 className="header">Sign Up for a SafeTP account</h2>
            <Row className="sign-buttons">
              <Col xs={12} md={6}>
                <FormTextField
                  formName="name"
                  spanText="Name"
                  value={formValues.name}
                  validate={required}
                  onChange={handleInputChange}
                />
              </Col>

              <Col xs={12} md={6}>
                <FormTextField
                  formName="surname"
                  spanText="Surname"
                  value={formValues.surname}
                  validate={required}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormTextField
                  formName="email"
                  spanText="E-Mail"
                  value={email}
                  validate={composeValidators(required, validEmail)}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setShowExitPrompt(true);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <FormTextField
                  spanText="Password"
                  formName="password"
                  validate={composeValidators(required, validPassword)}
                  type="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setShowExitPrompt(true);
                  }}
                />
              </Col>

              <Col md="6">
                <FormTextField
                  spanText="Confirm Password"
                  formName="confirm-password"
                  type="password"
                  validate={password != '' ? samePassword : required}
                />
              </Col>
            </Row>
            <Row>
              <div className="form-checkbox">
                <FormCheckbox
                  formName={'terms'}
                  value={terms}
                  validate={termsAndConditions}
                  onChange={() => {
                    setTerms(!terms);
                  }}
                />
                <label className="checkbox-label">
                  I agree to the <span> Terms and Conditions</span> (Important for both of us)
                </label>
              </div>

              <div className="form-checkbox">
                <FormCheckbox
                  formName={'optin'}
                  value={optin}
                  onChange={() => {
                    setOptin(!optin);
                  }}
                />
                <label className="checkbox-label">Get the Latest News and Updates.</label>
              </div>
            </Row>
            <Row>
              {/* <Col className="signin-col">
                {signInSelect && (
                  <div className="signin-options">
                    <div className="option">
                      <p>Sign In with Apple</p>
                      <img src={appleIcon} />
                    </div>
                    <div className="option">
                      <p>Sign In with Google</p>
                      <img src={googleIcon} />
                    </div>
                    <Link to="/signin">
                      <div className="option">
                        <p>Sign In with SafeTP</p>
                        <img src={enterIcon} />
                      </div>
                    </Link>
                  </div>
                )}

                <div className="signin-button">
                  <p
                    onClick={() => {
                      {
                        //TODO: implement
                        console.log('submitted');
                      }
                    }}
                  >
                    Sign In
                  </p>
                  <img onClick={() => setSignInSelect(!signInSelect)} src={signInSelect ? minusIcon : plusIcon} />
                </div>
              </Col> */}
              <Col className="signin-col">
                <Link
                  to="/signin"
                  onClick={() => {
                    gtagEvent({
                      action: 'sign_in_start',
                      category: 'Sign In',
                      label: 'From Sign Up',
                      value: 1,
                      method: 'from_sign_up',
                    });
                  }}
                >
                  <Button className="signin-button" disabled={submitting}>
                    Sign In
                  </Button>
                </Link>
              </Col>
              <Col>
                <button className="signup-button" onClick={handleSubmit} disabled={submitting}>
                  Sign Up
                </button>
              </Col>
            </Row>
          </form>
        )}
      />

      <div className="signup-form"></div>
    </Col>
  );
};

export default FormColumn;
