import React from 'react';

export const ProfileIconBig = () => {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40 0C17.908 0 0 17.908 0 40C0 62.092 17.908 80 40 80C62.092 80 80 62.092 80 40C80 17.908 62.092 0 40 0ZM40 8C57.672 8 72 22.328 72 40C72 46.3892 70.1016 52.3244 66.875 57.3203L63.7969 54.9375C57.5889 50.1495 46.148 48 40 48C33.852 48 22.4071 50.1495 16.2031 54.9375L13.125 57.3281C9.89617 52.3311 8 46.3916 8 40C8 22.328 22.328 8 40 8ZM40 15C32.832 15 27 20.832 27 28C27 35.168 32.832 41 40 41C47.168 41 53 35.168 53 28C53 20.832 47.168 15 40 15ZM40 23C42.756 23 45 25.244 45 28C45 30.756 42.756 33 40 33C37.244 33 35 30.756 35 28C35 25.244 37.244 23 40 23ZM40 56C52.4029 56 59.0724 60.6739 62.1953 63.0156C56.4399 68.5673 48.6274 72 40 72C31.3726 72 23.5601 68.5673 17.8047 63.0156C20.9276 60.6739 27.5971 56 40 56ZM16.2188 61.3594C16.7026 61.8925 17.1891 62.4234 17.7031 62.9219C17.1885 62.4211 16.6989 61.8935 16.2188 61.3594ZM63.6484 61.5C63.2097 61.9822 62.7717 62.4673 62.3047 62.9219C62.7705 62.4701 63.2058 61.9799 63.6484 61.5Z"
        fill="white"
      />
    </svg>
  );
};
