import React from 'react';

export const ValidPasswordRequirement = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="5.5" fill="white" stroke="#D9D9D9" />
      <path
        d="M6 1C3.2385 1 1 3.2385 1 6C1 8.7615 3.2385 11 6 11C8.7615 11 11 8.7615 11 6C11 3.2385 8.7615 1 6 1ZM5 8.707L2.6465 6.3535L3.3535 5.6465L5 7.293L8.6465 3.6465L9.3535 4.3535L5 8.707Z"
        fill="#478FF3"
      />
    </svg>
  );
};
