import React, { useState } from 'react';
import { Button, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import mime from 'react-native-mime-types';
import PeopleIcon from '../../icons/PeopleIcon';
import { SmallInfoIcon } from '../../icons/SmallInfoIcon';
import CalendarIcon from '../../icons/CalendarIcon';
import { AddPasswordIcon } from '../../icons/AddPasswordIcon';
import ExpirationIcon from '../../icons/ExpirationIcon';
import { format } from 'date-fns';
import OkayIcon from '../../icons/OkayIcon';
import { ValidationErrorIcon } from '../../icons/ValidationErrorIcon';
import { RecentTransferIcon } from '../../icons/RecentTransferIcon';
import FilePreview from '../transfer/FilePreview';
import DownloadIcon from '../../icons/DownloadIcon';
import { formatBytes } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';
import DownTriangle from '../common/DownTriangle';

const TransferDetail = ({ deleteTransfer }) => {
  const { transfer } = useSelector((state) => state.recentTransfers);
  const [show, setShow] = useState(false);

  if (!transfer) return <Col className="transfer-detail"></Col>;

  const onDelete = async () => {
    await deleteTransfer(transfer);
  };

  return (
    <Col className="transfer-detail" md={6} xs={12}>
      <ToastContainer />
      <h1 className="header">
        {transfer.files.length} {transfer.files.length == 1 ? 'file' : 'files'}
      </h1>
      <Row className="people-info-row">
        <Col className="people">
          <div className="header">
            <div className="icon">
              <PeopleIcon color="#FFF" />
            </div>
            <span>People:</span>
          </div>
          <div className="people-list">
            {transfer.receiverEmails &&
              transfer.receiverEmails.map((email, index) => {
                return (
                  <div className="people-info" key={index}>
                    <span
                      className="email-to"
                      style={{
                        color: '#' + transfer.receiverEmailColors[index],
                        border: `2.5px solid #${transfer.receiverEmailColors[index]}`,
                      }}
                    >
                      {email[0].toUpperCase()}
                    </span>
                    <OverlayTrigger placement="bottom" overlay={<Tooltip>{email}</Tooltip>}>
                      <span className="email">{email}</span>
                    </OverlayTrigger>
                  </div>
                );
              })}
          </div>
        </Col>
        <Col className="info">
          <div className="header">
            <div className="icon">
              <SmallInfoIcon />
            </div>
            <span>Info:</span>
          </div>
          <div className="details">
            <Row>
              <div className="detail">
                <div className="icon">
                  <CalendarIcon color="#2C2C2C" />
                </div>
                <span>Date: {format(new Date(transfer.createdAt), 'dd.MM.yyyy')}</span>
              </div>
            </Row>
            <Row>
              <div className="detail">
                <div className="icon">
                  <AddPasswordIcon color="#2C2C2C" />
                </div>
                <span>Password:</span>
                {transfer.isProtected ? (
                  <div style={{ display: 'inline-flex' }}>
                    <span className="yes">Yes</span>
                    <OkayIcon color="#38D047" />
                  </div>
                ) : (
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <span className="no">No</span>
                    <div className="no-icon">
                      <ValidationErrorIcon />
                    </div>
                  </div>
                )}
              </div>
            </Row>
            <Row>
              <div className="detail">
                <div className="icon">
                  <ExpirationIcon color="#2C2C2C" />
                </div>
                <span>Expiration: {format(new Date(transfer.expiredAt), 'dd.MM.yyyy')}</span>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="files-row">
        <div className="header">
          <div className="icon">
            <RecentTransferIcon color="#FFF" />
          </div>
          <span>Files:</span>
        </div>

        <div className="files">
          {transfer.files.map((file, index) => {
            file.type = mime.lookup(file.name);
            return (
              <Row key={index} className="file-info">
                <Col md={1}>
                  <FilePreview file={file} width="20" height="20" imagePreview={false} />
                </Col>
                <Col md={6} className="name">
                  {file.name}
                </Col>
                <Col className="size">{formatBytes(file.size)}</Col>
                {!(transfer.isExpired && !transfer.keepAfterExpire) && (
                  <Col className="download d-flex align-items-center">
                    <DownloadIcon />
                    <span onClick={() => window.open(file.link, '_blank')}>Download</span>
                  </Col>
                )}
              </Row>
            );
          })}
        </div>
      </Row>
      <Row className="buttons-row">
        <Col className="delete-button">
          {show && (
            <div className="delete-confirm-popup">
              <div className="delete-confirm-popup-header">
                <span className="confirmation-text-span">Are you sure you want to delete this transfer?</span>
              </div>
              <div className="button-container">
                <Button onClick={() => setShow(false)} className="btn-cancel">
                  Cancel
                </Button>
                <Button onClick={onDelete} className="btn-delete">
                  Delete
                </Button>
              </div>
            </div>
          )}
          <DownTriangle show={show} />
          <Button className="button" onClick={() => setShow(true)}>
            Delete the Transfer
          </Button>
        </Col>
        {!(transfer.isExpired && !transfer.keepAfterExpire) && (
          <Col className="download-all-button">
            <Button
              className="button"
              onClick={() => {
                if (transfer.link) {
                  window.open(transfer.link, '_blank');
                  return;
                }

                toast.success("Zip file's still in the oven! Check back soon!", {
                  position: toast.POSITION.TOP_CENTER,
                  className: 'toast-message',
                });
              }}
            >
              Download All
            </Button>
          </Col>
        )}
      </Row>
    </Col>
  );
};

export default TransferDetail;
