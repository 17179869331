import React, { useState } from 'react';

const DelayedHover = (props) => {
  const { id, className, component, children, time, afterHover } = props;
  const [hover, setHover] = useState(false);
  const [delayHandler, setDelayHandler] = useState(null);

  const hoverOnEnter = async () => {
    setDelayHandler(
      setTimeout(() => {
        setHover(!hover);
        if (typeof afterHover === 'function') {
          afterHover();
        }
      }, time),
    );
  };
  const hoverOnLeave = () => {
    clearTimeout(delayHandler);
    if (hover) setHover(!hover);
  };

  return (
    <div onMouseEnter={() => hoverOnEnter()} onMouseLeave={() => hoverOnLeave()} className={className} id={id}>
      {hover && component}
      {children}
    </div>
  );
};

export default DelayedHover;
