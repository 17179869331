const initialState = {
  transactionId: null,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TRANSACTION_ID':
      return {
        ...state,
        transactionId: action.payload.transactionId,
      };
    default:
      return state;
  }
};
