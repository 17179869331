import React from 'react';

export const ContactUsIcon = () => {
  return (
    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0C9.22808 0 7.79167 1.46906 7.79167 3.28125C7.79167 5.09344 9.22808 6.5625 11 6.5625C12.7719 6.5625 14.2083 5.09344 14.2083 3.28125C14.2083 1.46906 12.7719 0 11 0ZM11 1.875C11.7581 1.875 12.375 2.50594 12.375 3.28125C12.375 4.05656 11.7581 4.6875 11 4.6875C10.2419 4.6875 9.625 4.05656 9.625 3.28125C9.625 2.50594 10.2419 1.875 11 1.875ZM4.125 2.8125C2.85908 2.8125 1.83333 3.86156 1.83333 5.15625C1.83333 6.45094 2.85908 7.5 4.125 7.5C5.39092 7.5 6.41667 6.45094 6.41667 5.15625C6.41667 3.86156 5.39092 2.8125 4.125 2.8125ZM17.875 2.8125C16.6091 2.8125 15.5833 3.86156 15.5833 5.15625C15.5833 6.45094 16.6091 7.5 17.875 7.5C19.1409 7.5 20.1667 6.45094 20.1667 5.15625C20.1667 3.86156 19.1409 2.8125 17.875 2.8125ZM4.125 4.42749C4.51825 4.42749 4.83757 4.75406 4.83757 5.15625C4.83757 5.55844 4.51825 5.88501 4.125 5.88501C3.73175 5.88501 3.41244 5.55844 3.41244 5.15625C3.41244 4.75406 3.73175 4.42749 4.125 4.42749ZM17.875 4.42749C18.2682 4.42749 18.5876 4.75406 18.5876 5.15625C18.5876 5.55844 18.2682 5.88501 17.875 5.88501C17.4818 5.88501 17.1624 5.55844 17.1624 5.15625C17.1624 4.75406 17.4818 4.42749 17.875 4.42749ZM11 8.4375C9.64816 8.4375 7.44868 8.8765 6.00846 9.74854C5.42074 9.52055 4.77698 9.375 4.125 9.375C2.07442 9.375 0 10.6853 0 12.1234V15H4.125H4.58333H5.5H17.4167H17.875H22V12.1234C22 10.6853 19.9256 9.375 17.875 9.375C17.223 9.375 16.5793 9.52055 15.9915 9.74854C14.5513 8.8765 12.3518 8.4375 11 8.4375ZM11 10.3125C12.9562 10.3125 15.2368 11.2138 15.5833 11.781V13.125H6.41667V11.781C6.76317 11.2138 9.04383 10.3125 11 10.3125ZM4.125 11.25C4.30645 11.25 4.47031 11.2818 4.63704 11.3086C4.60299 11.4407 4.58333 11.5775 4.58333 11.7188V13.125H4.125H1.83333V12.1967C2.002 11.8967 2.9315 11.25 4.125 11.25ZM17.875 11.25C19.0685 11.25 19.998 11.8967 20.1667 12.1967V13.125H17.875H17.4167V11.7188C17.4167 11.5775 17.397 11.4407 17.363 11.3086C17.5297 11.2818 17.6936 11.25 17.875 11.25Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};
