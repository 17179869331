import React, { useEffect, useState } from 'react';

import { Row } from 'react-bootstrap';
import ErrorPopup from '../common/ErrorPopup';
import '../../scss/components/common/form.scss';
import '../../scss/pages/transfer.scss';
import PreviewColumn from './PreviewColumn';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../store';

import ShareColumn from './ShareColumn';
import SendingProcess from './SendingProcess/SendingProcess';
import { SENDING_STATUS } from '../../utils/constants';
import { setInitialState } from '../../store/transfer/actions';
import Background from '../common/Background';
import useExitPrompt from '../common/useExitPrompt';

const TransferPage = () => {
  const dispatch = useDispatch();

  const [errorPopupStatus, setErrorPopupStatus] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);
  let { status } = useSelector((state) => state.transfer);
  useEffect(() => {
    if (status == SENDING_STATUS.SENT || status == SENDING_STATUS.ERROR) {
      store.dispatch(setInitialState());
    }
  }, [dispatch]);

  const [errorText, setErrorText] = useState(
    'The files you are trying to select are larger than your current allowed limit (5 gb)',
  );

  useEffect(() => {
    console.log({ showExitPrompt });
    return () => {
      setShowExitPrompt(false);
    };
  }, []);

  return (
    <div className="transferpage">
      <Background />
      {status == SENDING_STATUS.CREATED ? (
        <Row className="main-row">
          <PreviewColumn
            errorCallback={(text) => {
              setErrorText(text);
              setErrorPopupStatus(true);
            }}
            setShowExitPrompt={setShowExitPrompt}
          />
          <ShareColumn
            errorCallback={(text) => {
              setErrorText(text);
              setErrorPopupStatus(true);
            }}
            setShowExitPrompt={setShowExitPrompt}
          />
        </Row>
      ) : (
        <SendingProcess
          errorCallback={(text) => {
            setErrorText(text);
            setErrorPopupStatus(true);
          }}
        />
      )}
      {errorPopupStatus && <ErrorPopup text={errorText} actionText="Retry" action={() => setErrorPopupStatus(false)} />}
    </div>
  );
};

export default TransferPage;
