import React from 'react';
import { ValidationErrorIcon } from '../../../icons/ValidationErrorIcon';
import { isValidationErr } from '../../../utils/form';

const FormValidation = ({ meta }) => {
  return (
    <div className="validation-error">
      {isValidationErr(meta) && (
        <div>
          <ValidationErrorIcon />
          <div className="validation-text-container">
            <div className="up-triangle"></div>
            <span>{meta.error || meta.submitError}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormValidation;
