import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { UPLOAD_LIMIT_BY_BYTE_DEFAULT, UPLOAD_LIMIT_BY_BYTE_REGISTERED } from '../../constants';
import { LargePlusIcon } from '../../icons/LargePlusIcon';
import { LogoIcon } from '../../icons/LogoIcon';
import ErrorPopup from '../common/ErrorPopup';
import Dropzone from '../common/Dropzone';
import Animation from './Animation';
import '../../scss/pages/landing.scss';
import { store } from '../../store';
import { setLandingPageMinimized, setScrollY } from '../../store/scroll/actions';
import { setFiles, setInitialState } from '../../store/transfer/actions';
import { isAtBottom, isAtTop, isTouchDown, isTouchUp } from '../../utils/scroll';
import Introduction from '../common/Introduction';
import { haveAccount, isMobile } from '../../utils';
import { gtagEvent } from '../../utils/analytics';

const LandingPage = () => {
  let { max: maxScroll, y: scrollY, minimized } = useSelector((state) => state.scroll);
  const [lastYForMobile, setlastYForMobile] = useState(0);

  const history = useHistory();
  let files = [];
  const [errorPopupStatus, setErrorPopupStatus] = React.useState(false);
  const [dropzoneStatus, setDropzoneStatus] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [errorText, setErrorText] = React.useState(
    'The files you are trying to select are larger than your current allowed limit (5 gb)',
  );

  const root = document.documentElement;
  root?.style.setProperty('--header-text-color', '#8892A4');

  useEffect(() => {
    document.body.className = document.body.className.replace('height-based-scrollable', '');
    return () => {
      document.body.className = `${document.body.className} height-based-scrollable`;
    };
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    store.dispatch(setInitialState());
  }, [dispatch]);

  const selectFiles = () => {
    const input = document.getElementById('file-input');
    if (input) {
      input.click();
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    setDropzoneStatus(true);
  };

  const handleDrop = function (files) {
    setDropzoneStatus(false);
    const selectedFiles = Array.prototype.slice.call(files);
    gtagEvent({
      action: 'transfer_start',
      category: 'Transfer',
      label: 'Drag and Drop',
      value: 1,
      method: 'drag_and_drop',
    });

    handleSelectedFiles(selectedFiles);
  };

  const validateFile = (file) => {
    for (let f of files) {
      if (f.name === file.name) {
        return false;
      }
    }

    return true;
  };

  const handleSelectedFiles = (selectedFiles) => {
    const totalSize = Array.from(selectedFiles).reduce((acc, file) => acc + file.size, 0);
    if (haveAccount() && totalSize > UPLOAD_LIMIT_BY_BYTE_REGISTERED) {
      setErrorPopupStatus(true);
      store.dispatch(setScrollY(maxScroll + 100));
      return;
    } else if (!haveAccount() && totalSize > UPLOAD_LIMIT_BY_BYTE_DEFAULT) {
      setErrorPopupStatus(true);
      setErrorText(
        'The files you are trying to select are larger than your current allowed limit (2 gb). \
         Please sign up your account to send larger files.',
      );
      store.dispatch(setScrollY(maxScroll + 100));
      return;
    }

    selectedFiles.forEach((file) => {
      if (!validateFile(file)) {
        setErrorText("Can't send multiple files with the same name. Please rename your files to be unique.");
        setErrorPopupStatus(true);
        store.dispatch(setScrollY(maxScroll + 100));
        return;
      }

      file.clientGUID = uuid();
      files = [...files, file];
      store.dispatch(setFiles(files));
    });

    history.push('../new');
  };

  const onSelectFiles = (e) => {
    const selectedFiles = Array.prototype.slice.call(e.target.files);
    if (selectedFiles.length === 0) {
      return;
    }

    gtagEvent({
      action: 'transfer_start',
      category: 'Transfer',
      label: 'File Selection',
      value: 1,
      method: 'file_selection',
    });

    handleSelectedFiles(selectedFiles);
  };

  return (
    <div
      className="landing"
      onTouchMove={(e) => {
        const currentY = e.touches[0].clientY;
        let y = scrollY;

        if (isTouchUp(currentY, lastYForMobile) && isAtBottom(currentY, 0)) {
          y = 1;
        } else if (isTouchDown(currentY, lastYForMobile) && isAtTop(currentY, maxScroll)) {
          y = maxScroll;
        } else {
          y = currentY;
        }

        setlastYForMobile(currentY);
        store.dispatch(setLandingPageMinimized(y < maxScroll));
        store.dispatch(setScrollY(y));
      }}
      onWheel={(e) => {
        if (isMobile()) {
          return;
        }
        if (scrollY < 0 && e.deltaY > 0) {
          return;
        }

        let y = scrollY - e.deltaY;

        if (e.deltaY > 0 && y < 0) {
          y = 1;
        }

        if (e.deltaY < 0 && y > maxScroll) {
          y = maxScroll;
        }

        store.dispatch(setLandingPageMinimized(y !== maxScroll));
        store.dispatch(setScrollY(y));
      }}
      style={{
        touchAction: 'none',
      }}
    >
      {dropzoneStatus && <Dropzone onLeave={() => setDropzoneStatus(false)} onFileDrop={handleDrop} />}
      <Animation />
      <Container>
        <Row className={`logo-container  ${minimized ? 'minimized' : 'nminimized'}`}>
          <Col md={3}>
            <LogoIcon />
          </Col>
        </Row>
        {!isMobile() && (
          <Row className="introduction-container">
            <Col md={12} lg={9} xl={6}>
              <Introduction />
            </Col>
          </Row>
        )}
        <Row>
          <Col
            className={`landing-container ${minimized ? 'minimized miniscale' : 'nminimized'} ${
              errorPopupStatus ? 'error' : ''
            }`}
            md={6}
            lg={3}
            onClick={selectFiles}
            onDragEnter={handleDrag}
          >
            <div>
              <form>
                <input
                  type="file"
                  id="file-input"
                  style={{ display: 'none' }}
                  multiple
                  onChange={onSelectFiles}
                  value={inputValue}
                  onClick={() => setInputValue('')}
                />
              </form>
              <div>
                <LargePlusIcon className="plus-icon" />
              </div>
              <p className="color-primary landing-text">add files up to 5gb</p>
              <p className="text-secondary landing-text-2">Secure file transfers with advanced encryption.</p>
            </div>
          </Col>
        </Row>

        {errorPopupStatus && (
          <ErrorPopup
            text={errorText}
            actionText="Retry"
            action={() => {
              setErrorPopupStatus(false);
              store.dispatch(setScrollY(maxScroll));
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default LandingPage;
