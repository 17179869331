import React from 'react';

const ExpirationIcon = ({ color = '#2C2C2C' }) => (
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.08592 0C3.62788 0 0 3.6342 0 8.1C0 12.2499 3.13364 15.6776 7.15414 16.142C7.20625 16.007 7.24717 15.8673 7.32084 15.7395L8.10171 14.3982C8.09632 14.3982 8.09131 14.4 8.08592 14.4C4.61796 14.4 1.79687 11.574 1.79687 8.1C1.79687 4.626 4.61796 1.8 8.08592 1.8C10.9367 1.8 13.3462 3.71199 14.1153 6.32109C14.2024 6.31209 14.286 6.28594 14.375 6.28594C15.0075 6.28594 15.5992 6.52811 16.0753 6.92051C15.5021 3.01271 12.1433 0 8.08592 0ZM7.18748 3.6V7.72734L4.75539 10.1637L6.02583 11.4363L8.98435 8.47266V3.6H7.18748ZM14.375 8.08594C14.0735 8.08594 13.7728 8.23452 13.5994 8.53242L8.8738 16.6465C8.52431 17.2468 8.95581 18 9.6494 18H19.1005C19.7941 18 20.2256 17.2468 19.8761 16.6465L15.1506 8.53242C14.9772 8.23452 14.6764 8.08594 14.375 8.08594ZM13.4765 10.8H15.2734V14.4H13.4765V10.8ZM13.4765 15.3H15.2734V17.1H13.4765V15.3Z"
      fill={color}
    />
  </svg>
);

export default ExpirationIcon;
