import React from 'react';

export const AddSendToMailIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.75848 0.44014C4.49994 0.44014 0.237305 4.70277 0.237305 9.96132C0.237305 15.2199 4.49994 19.4825 9.75848 19.4825C15.017 19.4825 19.2797 15.2199 19.2797 9.96132C19.2797 4.70277 15.017 0.44014 9.75848 0.44014ZM14.5191 10.9134H10.7106V14.7219H8.80636V10.9134H4.99789V9.0092H8.80636V5.20073H10.7106V9.0092H14.5191V10.9134Z"
        fill="#478FF3"
      />
    </svg>
  );
};
