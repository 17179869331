import React from 'react';

const FileSentIcon = ({ color = '#8892A4' }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M52.5359 35.811L52.5359 11L27.513 11L35.2124 18.8474L13.7431 40.3167C12.5092 41.9948 10.5308 46.5549 13.7431 50.3851C17.5925 54.9751 23.9595 52.458 25.5882 50.8293C26.8912 49.5264 38.5685 37.5529 44.2443 31.729C44.9846 30.89 45.7325 28.7753 43.652 26.6948C41.4311 24.4739 39.2595 25.461 38.4698 25.9545L21.2943 42.8338"
        stroke={color}
        strokeWidth="4.0234"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default FileSentIcon;
