const initialState = {
  y: 0,
  minimized: false,
};

export const scrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SCROLL':
      return {
        ...state,
        y: action.payload.scrollY,
      };
    case 'MAX_SCROLL':
      return {
        ...state,
        max: action.payload.max,
      };
    case 'LANDING_MINIMIZED':
      return {
        ...state,
        minimized: action.payload.minimized,
      };
    default:
      return state;
  }
};
