import React from 'react';

export const ButtonPlusLarge = () => {
  return (
    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_5_2174)">
        <circle cx="36" cy="34" r="31" fill="white" />
        <circle cx="36" cy="34" r="30.5" stroke="#D9D9D9" />
      </g>
      <path
        d="M36 14C24.978 14 16 22.978 16 34C16 45.022 24.978 54 36 54C47.022 54 56 45.022 56 34C56 22.978 47.022 14 36 14ZM36 18C44.8602 18 52 25.1398 52 34C52 42.8602 44.8602 50 36 50C27.1398 50 20 42.8602 20 34C20 25.1398 27.1398 18 36 18ZM34 24V32H26V36H34V44H38V36H46V32H38V24H34Z"
        fill="#478FF3"
      />
      <defs>
        <filter
          id="filter0_d_5_2174"
          x="0"
          y="0"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_2174" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_2174" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
