import React, { useState, useRef } from 'react';

import Slider from 'rc-slider';
import { setStatus, setForm } from '../../store/transfer/actions';
import { store } from '../../store';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { AddPasswordIcon } from '../../icons/AddPasswordIcon';
import { AddSendToMailIcon } from '../../icons/AddSendToMailIcon';
import { CopyLinkIcon } from '../../icons/CopyLinkIcon';
import { DisablePasswordIcon } from '../../icons/DisablePasswordIcon';
import { SendMailIcon } from '../../icons/SendMailIcon';
import { ShowPasswordIcon } from '../../icons/ShowPasswordIcon';
import { haveAccount, randomColor } from '../../utils';
import { composeValidators, hasDuplicates, isInList, required, validEmail } from '../../utils/form';
import { FormHiddenField } from '../common/form/FormHiddenField';
import FormTextArea from '../common/form/FormTextArea';
import FormTextField from '../common/form/FormTextField';
import FormValidation from '../common/form/FormValidation';
import FormSwitch from '../common/form/FormSwitch';
import { SmallInfoIcon } from '../../icons/SmallInfoIcon';
import '../../scss/index.scss';
import '../../scss/components/common/form.scss';
import { handleOutsideClick } from '../../utils/component';
import DelayedHover from '../common/DelayedHover';
import { SENDING_STATUS } from '../../utils/constants';
import { gtagEvent } from '../../utils/analytics';

const ShareColumn = ({ errorCallback, setShowExitPrompt }) => {
  const maxEmailToList = 30;
  const [sharingChannel, setSharingChannel] = useState('email');
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [emailTo, setEmailTo] = useState('');
  const [valErrs, setValErrs] = useState({
    toEmail: null,
  });
  let { form, files } = useSelector((state) => state.transfer);
  const [enablePasswordField, setEnablePasswordField] = useState(form.password != '');
  const emailsListRef = useRef(null);
  handleOutsideClick(emailsListRef, () => {
    const listElement = document.getElementById('email-full-list').classList;
    if (!listElement.contains('active')) {
      return;
    }

    startCloseEmailToList();
  });

  const addToEmailList = (email) => {
    if (!email || email == '') {
      return;
    }

    const color = '#' + randomColor();
    form.toEmailList.push({ email, color });
    store.dispatch(setForm(form));
  };

  const handleToEmail = (emailTo) => {
    let valErr = required(emailTo);
    if (valErr) {
      setValErrs({ ...valErrs, toEmail: valErr });
      return;
    }

    const emails = emailTo.split(';');
    for (const email of emails) {
      valErr = validEmail(email);
      if (valErr) {
        setValErrs({ ...valErrs, toEmail: valErr });
        return;
      }

      valErr = isInList(
        email,
        form.toEmailList.map((e) => e.email),
      );

      if (valErr) {
        setValErrs({ ...valErrs, toEmail: valErr });
        return;
      }

      valErr = hasDuplicates(emails);
      if (valErr) {
        setValErrs({ ...valErrs, toEmail: valErr });
        return;
      }
    }

    emails.forEach((email) => addToEmailList(email));

    setEmailTo('');
    setValErrs({ ...valErrs, toEmail: null });
  };

  const handleAddToEmailList = (e) => {
    if (e.key && e.key !== 'Enter') {
      return;
    }

    e.preventDefault();

    handleToEmail(emailTo);
  };

  const onSubmit = async () => {
    if (files == null || files.length == 0) {
      errorCallback('Please select files to send');
      return;
    }
    if (emailTo != '') {
      handleToEmail(emailTo);
    }

    gtagEvent({
      action: 'pre_submit',
      category: 'Transfer',
      label: 'Pre Submit',
      value: 1,
    });

    store.dispatch(setStatus(SENDING_STATUS.PRE_SUBMIT));
  };

  const expireSliderMarks = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 14,
    9: 30,
    10: 90,
  };

  const showEmailToList = () => {
    const listElementClasses = document.getElementById('email-full-list').classList;
    const toEmailListContainer = document.getElementById('to-email-list-container').classList;
    toEmailListContainer.add('d-none');
    listElementClasses.remove('deactive');
    listElementClasses.remove('d-none');
    listElementClasses.add('active');
    const formGroups = document.querySelectorAll('.share-col .custom-form-group');
    formGroups.forEach((element) => {
      if (!element.classList.contains('add-email-to')) {
        element.classList.add('op-3');
      }
    });
    document.getElementById('send-container').classList.add('op-3');
  };

  const startCloseEmailToList = () => {
    const listElementClasses = document.getElementById('email-full-list').classList;
    listElementClasses.remove('active');
    listElementClasses.add('deactive');
  };

  const hideEmailToList = () => {
    const listElement = document.getElementById('email-full-list').classList;
    if (!listElement.contains('deactive')) {
      return;
    }

    const toEmailListContainer = document.getElementById('to-email-list-container');
    if (toEmailListContainer) {
      toEmailListContainer.classList.remove('d-none');
    }

    listElement.add('d-none');

    const formGroups = document.querySelectorAll('.share-col .op-3');
    formGroups.forEach((element) => {
      element.classList.remove('op-3');
    });
  };

  const removeFromEmailList = (index) => {
    const toEmailListObject = [...form.toEmailList];
    toEmailListObject.splice(index, 1);
    form.toEmailList = toEmailListObject;
    store.dispatch(setForm(form));
  };

  return (
    <Col md={6} xs={12} className="share-col">
      <Form
        onSubmit={onSubmit}
        initialValues={{
          email: form.email,
          sharingChannel,
          expireDuration: form.expire.duration,
          keepAfterExpired: form.expire.keep,
          toEmailList: form.toEmailList,
          message: form.message,
          password: form.password,
        }}
        keepDirtyOnReinitialize={true}
        render={({ handleSubmit, submitting }) => (
          <form>
            <Row className="share-option-container">
              <Col md={8} className="share-option-buttons">
                <Button
                  className={sharingChannel === 'email' ? 'active' : ''}
                  onClick={() => setSharingChannel('email')}
                >
                  <p>
                    <strong>Send Email</strong>
                  </p>
                  <SendMailIcon />
                </Button>
                <Button className={sharingChannel === 'link' ? 'active' : ''} onClick={() => setSharingChannel('link')}>
                  <p>
                    <strong>Copy Link</strong>
                  </p>
                  <CopyLinkIcon />
                </Button>
                <FormHiddenField value={sharingChannel} formName="sharingChannel" />
              </Col>
            </Row>
            <Row>
              <Col md={sharingChannel === 'email' ? 6 : 12}>
                <FormTextField
                  formName="email"
                  spanText="Your Email"
                  value={form.email}
                  validate={composeValidators(required, validEmail)}
                  onChange={(e) => {
                    form.email = e.target.value;
                    store.dispatch(setForm(form));
                    setShowExitPrompt(true);
                  }}
                  disabled={haveAccount()}
                />
              </Col>
              <Col
                className="email-to"
                md={6}
                style={{
                  display: sharingChannel === 'email' ? 'block' : 'none',
                }}
              >
                <div className="custom-form-group add-email-to">
                  <FormHiddenField value={form.toEmailList} formName="toEmailList" />
                  <span className="input-text">Email To</span>
                  <div style={{ position: 'relative' }}>
                    <input
                      type="text"
                      className="text"
                      onKeyDown={handleAddToEmailList}
                      onChange={(e) => {
                        setEmailTo(e.target.value);
                        setValErrs({ ...valErrs, toEmail: null });
                        setShowExitPrompt(true);
                      }}
                      value={emailTo}
                      autoComplete="new-password"
                    />
                    <Button
                      className="btn-add-email-to"
                      disabled={form.toEmailList.length >= maxEmailToList}
                      onClick={handleAddToEmailList}
                    >
                      <AddSendToMailIcon />
                    </Button>
                    <FormValidation
                      meta={{ error: valErrs.toEmail, override: valErrs.toEmail != null, touched: true }}
                    />
                  </div>
                  <div>
                    {form.toEmailList.length > 0 && (
                      <DelayedHover
                        className="to-email-list-container"
                        id="to-email-list-container"
                        afterHover={showEmailToList}
                        time={500}
                      >
                        <Col className="to-email-list">
                          {form.toEmailList.map((emailObj, index) => {
                            if (index > 4) {
                              return;
                            }
                            return (
                              <div
                                className="email-to"
                                style={{
                                  border: `2px solid ${emailObj.color}`,
                                }}
                                key={emailObj.email}
                              >
                                <span style={{ color: emailObj.color }}>{emailObj.email[0].toUpperCase()}</span>
                              </div>
                            );
                          })}
                        </Col>
                      </DelayedHover>
                    )}
                    <div
                      className="to-email-list-full-container"
                      id="email-full-list"
                      onAnimationEndCapture={hideEmailToList}
                      ref={emailsListRef}
                    >
                      <div className="emails">
                        {form.toEmailList.map((emailObj, index) => {
                          return (
                            <Row key={emailObj.email}>
                              <Col md={2}>
                                <div
                                  className="email-to"
                                  style={{
                                    border: `3px solid ${emailObj.color}`,
                                  }}
                                >
                                  <span style={{ color: emailObj.color }}>{emailObj.email[0].toUpperCase()}</span>
                                </div>
                              </Col>
                              <Col md={7} className="email-text">
                                <p>{emailObj.email}</p>
                              </Col>
                              <Col md={2}>
                                <button
                                  value={index}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    removeFromEmailList(index);
                                  }}
                                >
                                  X
                                </button>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                      <div className="buttons">
                        <Col md={6}>
                          <button
                            className="btn-remove-all"
                            onClick={(e) => {
                              e.preventDefault();
                              form.toEmailList = [];
                              store.dispatch(setForm(form));
                              startCloseEmailToList();
                            }}
                          >
                            Remove All
                          </button>
                        </Col>
                        <Col md={5}>
                          <button
                            className="btn-close-list"
                            onClick={(e) => {
                              e.preventDefault();
                              startCloseEmailToList();
                            }}
                          >
                            Close
                          </button>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="custom-form-group expiration">
                  <FormHiddenField value={form.expire.duration} formName="expireDuration" />
                  <span className="input-text">Expiration</span>
                  <div className="slider-parent">
                    <Col md={3}>
                      <strong>{`${form.expire.duration} day${form.expire.duration > 1 ? 's' : ''}`}</strong>
                    </Col>
                    <Col
                      md={9}
                      style={{
                        paddingRight: '5%',
                      }}
                    >
                      <Slider
                        min={1}
                        max={10}
                        defaultValue={form.expire.duration}
                        marks={expireSliderMarks}
                        onChange={(value) => {
                          form.expire.duration = expireSliderMarks[value];
                          store.dispatch(setForm(form));
                          setShowExitPrompt(true);
                        }}
                      />
                    </Col>
                  </div>
                  <div className="keep-switch-container">
                    <FormSwitch
                      formName={'keepAfterExpired'}
                      value={form.expire.keep}
                      onChange={(e) => {
                        form.expire.keep = e.target.value !== 'false';
                        store.dispatch(setForm(form));
                        setShowExitPrompt(true);
                      }}
                    />
                    <span>Keep after expiration</span>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          Use keep after expiration to securely save files in Recent Transfers for future access.
                        </Tooltip>
                      }
                    >
                      <div className="info-icon">
                        <SmallInfoIcon />
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormTextArea
                  formName="message"
                  value={form.message}
                  spanText="Message"
                  placeholder="Type your message here"
                  maxLength={1000}
                  onChange={(e) => {
                    form.message = e.target.value;
                    store.dispatch(setForm(form));
                    setShowExitPrompt(true);
                  }}
                />
              </Col>
            </Row>
            <Row className="send-container" id="send-container">
              <Col md={12}>
                <Col md={5}>
                  <Button
                    className={'btn-add-password' + (enablePasswordField ? '' : ' active')}
                    onClick={() => setEnablePasswordField(true)}
                  >
                    <p>Add Password</p>
                    <AddPasswordIcon />
                  </Button>
                  <div className={'password-container' + (enablePasswordField ? ' active' : '')}>
                    <FormTextField
                      formName="password"
                      value={form.password}
                      type={showPasswordField ? 'text' : 'password'}
                      validate={enablePasswordField ? required : undefined}
                      autoComplete="new-password"
                      onChange={(e) => {
                        form.password = e.target.value;
                        store.dispatch(setForm(form));
                        setShowExitPrompt(true);
                      }}
                    />
                    <Button className="btn-show-password" onClick={() => setShowPasswordField(!showPasswordField)}>
                      <ShowPasswordIcon />
                    </Button>
                    <Button
                      className="btn-disable-password"
                      onClick={() => {
                        setEnablePasswordField(false);
                        setShowPasswordField(false);
                        form.password = '';
                        store.dispatch(setForm(form));
                      }}
                    >
                      <DisablePasswordIcon />
                    </Button>
                  </div>
                </Col>
                <Col md={3}>
                  <Button type="button" onClick={handleSubmit} className="btn-send" disabled={submitting}>
                    <p>Send</p>
                  </Button>
                </Col>
              </Col>
            </Row>
          </form>
        )}
      />
    </Col>
  );
};

export default ShareColumn;
