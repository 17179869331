export const formatBytes = function (bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const randomColor = () => (Math.random().toString(16) + '00000').slice(2, 8);

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const setExpirationDate = (days) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + days);

  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();

  return `${day}.${month}.${year}`;
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isValidJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

export const haveAccount = () => {
  return localStorage.getItem('account') && isValidJSON(localStorage.getItem('account'));
};
