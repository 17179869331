import React from 'react';

const ColorPicker = ({ colors, bgColor, handleColorChange }) => {
  return (
    <div className="color-picker-container">
      <Color
        active={bgColor?.name === colors.BLUE.name}
        color={colors.BLUE.name}
        hex={colors.BLUE.hex}
        onClick={() => handleColorChange(colors.BLUE)}
      />
      <Color
        active={bgColor?.name === colors.ORANGE.name}
        color={colors.ORANGE.name}
        hex={colors.ORANGE.hex}
        onClick={() => handleColorChange(colors.ORANGE)}
      />
      <Color
        active={bgColor?.name === colors.PINK.name}
        color={colors.PINK.name}
        hex={colors.PINK.hex}
        onClick={() => handleColorChange(colors.PINK)}
      />
      <Color
        active={bgColor?.name === colors.BLACK.name}
        color={colors.BLACK.name}
        hex={colors.BLACK.hex}
        onClick={() => handleColorChange(colors.BLACK)}
      />
      <Color
        active={bgColor?.name === colors.GRAY.name}
        color={colors.GRAY.name}
        hex={colors.GRAY.hex}
        onClick={() => handleColorChange(colors.GRAY)}
      />
    </div>
  );
};

const Color = ({ active, hex, onClick }) => {
  return <div className={`color ${active ? 'active' : ''}`} style={{ backgroundColor: hex }} onClick={onClick}></div>;
};

export default ColorPicker;
