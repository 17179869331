import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import api from '../api';
import { setAccount } from '../store/account/actions';
import { setTransactionId } from '../store/app/actions';
import '../scss/index.scss';

import CookiePolicy from './common/CookiePolicy';
import Header from './common/Header';
import ResetPasswordPage from './resetpassword/ResetPasswordPage';
import TransferPage from './transfer/TransferPage';
import LandingPage from './landing/LandingPage';
import Profile from './profile/ProfilePage';
import RecentTransfersPage from './recenttransfers/RecentTransfersPage';
import SignInPage from './signin/SignInPage';
import SignUpPage from './signup/SignUpPage';
import TermsPage from './terms/Terms';
import VerifyPage from './verify/VerifyPage';
import DownloadPage from './download/DownloadPage';
import ContactUsPage from './contact-us/ContactUs';
import ErrorPage from './error/ErrorPage';
import SafeZonePage from './safezone/SafeZonePage';
import { useHistory } from 'react-router-dom';
import { haveAccount } from '../utils';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchInit = () => async (dispatch) => {
    try {
      const response = await api.get('/init');
      const { data } = response.data;
      dispatch(setTransactionId(data.transactionId));
    } catch (error) {
      console.error('Failed to fetch init:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRouteChange = () => {
    if (window.location.pathname === '/') {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  };

  useEffect(() => {
    dispatch(fetchInit());

    if (haveAccount()) {
      dispatch(setAccount(JSON.parse(localStorage.getItem('account'))));
    }

    handleRouteChange();
    history.listen(handleRouteChange);
  }, [dispatch, history]);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <div>
      <CookiePolicy />
      <Header />
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/new" component={TransferPage} />
        <Route path="/contact-us" component={ContactUsPage} />
        <Route path="/terms-conditions" component={TermsPage} />
        <Route path="/signup" component={SignUpPage} />
        <Route path="/signin" component={SignInPage} />
        <Route path="/verify-account" component={VerifyPage} />
        <Route path="/profile" component={Profile} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/recent-transfers" component={RecentTransfersPage} />
        <Route path="/safezone" component={SafeZonePage} />
        <Route path="/d/:id" component={DownloadPage} />
        <Route path="/error" component={ErrorPage} />
        <Route component={ErrorPage} />;
      </Switch>
    </div>
  );
}

export default App;
