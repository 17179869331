import React from 'react';

export const ImageIcon = ({ width, height }) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width || '48'} height={height || '48'} fill="#FFFFFF" />
      <path
        d="M8 8C5.81387 8 4 9.81387 4 12V36C4 38.1861 5.81387 40 8 40H40C42.1861 40 44 38.1861 44 36V12C44 9.81387 42.1861 8 40 8H8ZM8 12H40V36H8V12ZM20 16C19.4696 16 18.9609 16.2107 18.5858 16.5858C18.2107 16.9609 18 17.4696 18 18C18 18.5304 18.2107 19.0391 18.5858 19.4142C18.9609 19.7893 19.4696 20 20 20C20.5304 20 21.0391 19.7893 21.4142 19.4142C21.7893 19.0391 22 18.5304 22 18C22 17.4696 21.7893 16.9609 21.4142 16.5858C21.0391 16.2107 20.5304 16 20 16ZM29 22L22 30L17 25L11.5547 32H36.5L29 22Z"
        fill="#8892A4"
      />
    </svg>
  );
};
