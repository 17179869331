import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { cookieReducer } from './cookie/reducers';
import { scrollReducer } from './scroll/reducers';
import { transferReducer } from './transfer/reducers';
import { appReducer } from './app/reducers';
import { accountReducer } from './account/reducers';
import { recentTransfersReducer } from './recentTransfers/reducers';

const rootReducer = combineReducers({
  scroll: scrollReducer,
  cookie: cookieReducer,
  transfer: transferReducer,
  app: appReducer,
  account: accountReducer,
  recentTransfers: recentTransfersReducer,
});

const state = typeof window != 'undefined' ? window.INITIAL_STATE : {};

export const store = createStore(rootReducer, state, applyMiddleware(thunk));
