import React from 'react';
import { ValidPasswordRequirement } from '../../icons/ValidPasswordRequirement';
import { InvalidPasswordRequirement } from '../../icons/InvalidPasswordRequirement';

const Requirement = ({ text, rule }) => {
  const valid = rule && rule();

  return (
    <div className="requirement">
      {valid ? <ValidPasswordRequirement /> : <InvalidPasswordRequirement />}
      <span className={`requirement-text ${!valid ? 'blurred' : ''}`}>{text}</span>
    </div>
  );
};

export default Requirement;
