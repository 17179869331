export const UPLOAD_LIMIT_BY_BYTE_DEFAULT = 2 * 1000 * 1000 * 1000; // 2 GB

export const UPLOAD_LIMIT_BY_BYTE_REGISTERED = 5 * 1000 * 1000 * 1000; // 2 GB

export const MIME_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  OTHERS: 'others',
};
