import React from 'react';
import '../../scss/pages/safezone.scss';
import { Button, Container } from 'react-bootstrap';
import safezoneIllustration from '../../assets/illustrations/safezone.png';
import Background from '../common/Background';

const SafeZone = () => {
  document.body.style.overflowY = 'auto';

  return (
    <Container className="safezone">
      <Background />
      <div>
        <img className="illustration" src={safezoneIllustration} />
        <p>A sneak peek from the best file storage experience we&apos;ve been working on, coming soon.</p>
      </div>
      <div className="buttons">
        <Button
          className="button homepage-button"
          onClick={() => {
            window.location.href = '/';
          }}
        >
          Go to Homepage
        </Button>
      </div>
    </Container>
  );
};

export default SafeZone;
