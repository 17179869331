import React from 'react';
import '../../scss/components/common/landing-dropzone.scss';

const Dropzone = ({ onLeave, onFileDrop }) => {
  const handle = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onFileDrop(e.dataTransfer.files);
  };

  return (
    <div className="dropzone" onDrag={handle} onDragLeave={onLeave} onDragOver={handle} onDrop={handleDrop}>
      <p>Drop your files here</p>
    </div>
  );
};

export default Dropzone;
