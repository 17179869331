import React from 'react';

export const XlsIcon = ({ width, height }) => {
  return (
    <svg
      width={width || '48'}
      height={height || '48'}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width={width || '48'} height={height || '48'} fill="#FFFFFF" />
      <path
        d="M13 4C10.8115 4 9 5.81149 9 8V18C6.79 18 5 19.79 5 22V32C5 34.21 6.79 36 9 36V40C9 42.1885 10.8115 44 13 44H37C39.1885 44 41 42.1885 41 40V14L31 4H13ZM13 8H29V16H37V18H13V8ZM9.05078 22H11.5469L12.9961 25.4336L14.4492 22H16.9414L14.4062 26.957L17 32H14.4766L12.9961 28.5039L11.5234 32H9L11.5898 26.957L9.05078 22ZM19 22H21V30H25V32H19V22ZM29.9805 22C32.8265 22.082 33 24.5658 33 25.0078H31.0625C31.0625 24.8018 31.0973 23.6133 29.9453 23.6133C29.5953 23.6133 28.8111 23.7684 28.8711 24.7344C28.9291 25.6204 30.0946 26.0388 30.3086 26.1328C30.7566 26.2968 32.9742 27.2848 32.9922 29.3008C32.9962 29.7288 32.8855 31.97 30.0195 32C26.9015 32.034 26.6914 29.3509 26.6914 28.7969H28.6406C28.6406 29.0909 28.6655 30.5123 30.0195 30.4023C30.8335 30.3343 31.0111 29.7485 31.0391 29.3125C31.0851 28.5785 30.3845 28.1373 29.6445 27.7812C28.6045 27.2812 26.9597 26.6667 26.9297 24.7188C26.9037 22.9648 28.1925 21.95 29.9805 22ZM13 36H37V40H13V36Z"
        fill="#8892A4"
      />
    </svg>
  );
};
