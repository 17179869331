import React from 'react';

export const SendMailIcon = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0C0.895 0 0 0.895 0 2V14C0 15.105 0.895 16 2 16H18C19.105 16 20 15.105 20 14V2C20 0.895 19.105 0 18 0H2ZM2 2H18V3.00195L10 8L2 3.00195V2ZM2 5.00195L10 10L18 5.00195V14H2V5.00195Z"
        fill="#2C2C2C"
      />
    </svg>
  );
};
