import React from 'react';
import '../../scss/components/common/down-triangle.scss';

const DownTriangle = (props) => {
  const { show } = props;

  return (
    <div
      className="down-triangle"
      style={{
        display: show ? 'block' : 'none',
      }}
    ></div>
  );
};

export default DownTriangle;
